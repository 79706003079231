import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Error } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
    '& h2': {
      margin: 24,
      [theme.breakpoints.down('xs')]: {
        margin: 14,
      },
    },
  },
  logo: {
    maxHeight: 60,
    margin: 8,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 40,
    },
  },
}));

export interface TitleProps {
  name?: string | null;
  logoUrl?: string | null;
  role?: string;
}

export default function Title({ name, logoUrl, role }: TitleProps) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Container className={classes.root}>
        {logoUrl ? (
          <img
            className={classes.logo}
            src={logoUrl}
            alt={intl.formatMessage({ id: 'title.logo', defaultMessage: 'company logo' })}
          />
        ) : (
          <Typography variant="h2">{name}</Typography>
        )}
      </Container>
      {role === 'company' && (
        <Alert icon={<Error fontSize="small" />} severity="warning">
          Internal use only. Do not share this link with your customer.
        </Alert>
      )}
    </>
  );
}

import { useEffect, useRef } from 'react';

const useDidUpdate = (func: () => void, dependencies: any[], timeout = 0) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      const timeOutFunc = setTimeout(async () => {
        func();
      }, timeout);
      return () => clearTimeout(timeOutFunc);
    } else didMount.current = true;
  }, dependencies);
};

export default useDidUpdate;

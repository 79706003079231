import { FormCountry } from '@/services/address/types';
import { Typography, InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { useCountries } from '@/hooks/useCountries';

interface CountryAutocompleteProps {
  value: string;
  onChange: (
    event: React.ChangeEvent<{}>,
    value: FormCountry,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<FormCountry> | undefined,
  ) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      marginTop: 4,
    },
  },
}));

const DEFAULT_COUNTRY: FormCountry = {
  alpha2: '',
  name: '',
  taxIdRequired: false,
  postalCodeRegex: '',
};

export default function CountryAutocomplete({ value, onChange }: CountryAutocompleteProps) {
  const classes = useStyles();
  const { countries, isLoading } = useCountries();
  const currentCountry = countries.find((country) => country.alpha2 === value) ?? DEFAULT_COUNTRY;

  return (
    <InputLabel className={classes.root}>
      <Typography variant="body2">
        <FormattedMessage defaultMessage="Country" id="autocomplete.title" />
      </Typography>
      <Autocomplete
        size="small"
        options={countries}
        value={currentCountry}
        getOptionLabel={(option: FormCountry) => option.name || ''}
        getOptionSelected={(option, value) => value.name === '' || option.alpha2 === value.alpha2}
        disableClearable
        loading={isLoading}
        onChange={onChange}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" fullWidth required placeholder="Select a country" />
        )}
      />
    </InputLabel>
  );
}

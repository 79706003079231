import { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import BackerService from '@/services/backer';
import { CollectionStep, Shipment } from '@/services/backer/types';
import ProgressIndicator from '@/components/ProgressIndicator';

import { RouteParams } from '../App';
import IncotermsScreen from './IncotermsScreen';
import ShippingScreen from './ShippingScreen';
import SuccessScreen from './SuccessScreen';
import NotFoundScreen from './NotFoundScreen';
import InactiveScreen from './InactiveScreen';
import { determineIncotermsValues, emptyIncotermsValues } from '@/services/backer/helpers';
import { useTracking } from '@/providers/TrackingProvider';
import LandingScreen from './LandingScreen';
import AddressScreen from './AddressScreen';
import ErrorScreen from './ErrorScreen';

interface MainScreenProps {
  showErrorMessage: (error: Error) => void;
}

export default function MainScreen({ showErrorMessage }: MainScreenProps) {
  const history = useHistory();
  const params: RouteParams = useParams();
  const { registerUser } = useTracking();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const [step, setStep] = useState(CollectionStep.Loading);
  const [shipment, setShipment] = useState<Shipment>();
  const [incotermsValues, setIncotermsValues] = useState(emptyIncotermsValues);
  const [message, setMessage] = useState('');
  const [hasLeftLanding, setHasLeftLanding] = useState(false);
  const showLandingPage = !hasLeftLanding && shipment?.collect.current_step !== CollectionStep.Success;

  // Listen for back button
  history.listen((_, action: string) => {
    if (
      action === 'POP' &&
      shipment?.collect?.current_step === CollectionStep.Shipping &&
      shipment?.collect?.accessible_steps.includes(CollectionStep.Incoterms)
    ) {
      setStep(CollectionStep.Incoterms);
    }
  });

  const updateState = useCallback(
    (shipment: Shipment) => {
      if (!shipment) return;

      setShipment(shipment);

      const areSomeIncotermsZero = Object.values(incotermsValues).some((val) => val === 0);

      if (areSomeIncotermsZero) {
        const values = determineIncotermsValues(
          shipment.ddu_total_import_tax_and_duty,
          shipment.ddp_total_import_tax_and_duty,
        );
        setIncotermsValues(values);
      }

      const currentStep = shipment.collect.current_step;
      setStep(currentStep);

      const stepParam = params.paramOne;
      if (stepParam === currentStep) return;

      const path = `/collect/${currentStep}?token=${token}`;
      history.push(path);
    },
    [history, incotermsValues, params.paramOne, params.paramTwo, token],
  );

  const handleError = useCallback(
    (error: Error) => {
      showErrorMessage(error);

      if (error.message === 'Collect is not found.' || error.message.startsWith('Sorry, your session is expired.')) {
        setMessage(error.message);
        setStep(CollectionStep.NotFound);
      }
    },
    [showErrorMessage],
  );

  // Lookup shipment details
  useEffect(() => {
    if (shipment) return;

    function registerShipmentInMixpanel(shipment: Shipment): Shipment {
      registerUser({ collectId: shipment.id, companyName: shipment.company.name ?? '' });

      return shipment;
    }

    if (!token) return handleError(new Error('Collect is not found.'));

    BackerService.get(token).then(registerShipmentInMixpanel).then(updateState).catch(handleError);
  }, [shipment, token, updateState, handleError]);

  if (!token) return <NotFoundScreen message={message} />;

  const goToAddress = () => setStep(CollectionStep.Address);

  if (!shipment) return <ProgressIndicator centered size={3} />;

  if (showLandingPage) return <LandingScreen shipment={shipment} onClick={() => setHasLeftLanding(true)} />;

  switch (step) {
    case CollectionStep.Address:
      return <AddressScreen shipment={shipment} onAddressAccepted={updateState} token={token} />;
    case CollectionStep.Incoterms:
      return (
        <IncotermsScreen
          token={token}
          shipment={shipment}
          incotermsValues={incotermsValues}
          onError={handleError}
          updateState={updateState}
          goToAddress={goToAddress}
        />
      );

    case CollectionStep.Shipping:
      if (!shipment.rates) return <ProgressIndicator centered size={3} />;
      return (
        <ShippingScreen
          goToAddress={goToAddress}
          token={token}
          shipment={shipment}
          onError={handleError}
          updateState={updateState}
        />
      );

    case CollectionStep.Success:
      if (!shipment.shipment_collect) return <ProgressIndicator centered size={3} />;
      return <SuccessScreen shipment={shipment} />;

    case CollectionStep.NotFound:
      return <NotFoundScreen message={message} />;

    case CollectionStep.Inactive:
      if (!shipment.rates) return <ErrorScreen shipment={shipment} />;
      return <InactiveScreen shipment={shipment} />;

    default:
      return <ProgressIndicator centered size={3} />;
  }
}

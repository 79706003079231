import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Switch } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: 130,
    cursor: 'pointer',
  },
  no: { marginRight: -16 },
  yes: { marginLeft: -16 },
}));
export interface SwitchProps {
  checked: boolean;
  onToggle: (value: boolean) => void | Promise<void>;
  yesLabel?: string;
  noLabel?: string;
}

export default function InsuranceQuestion(props: SwitchProps) {
  const classes = useStyles();
  const { checked, onToggle, noLabel, yesLabel } = props;

  return (
    <Grid component="label" container alignItems="center" spacing={1} className={classes.root}>
      <Grid item className={classes.no}>
        {noLabel || <FormattedMessage id="switch.no" defaultMessage="No"></FormattedMessage>}
      </Grid>
      <Grid item>
        <Switch color="default" checked={checked} onChange={() => onToggle(!checked)} />
      </Grid>
      <Grid item className={classes.yes}>
        {yesLabel || <FormattedMessage id="switch.yes" defaultMessage="Yes"></FormattedMessage>}
      </Grid>
    </Grid>
  );
}

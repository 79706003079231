import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';

import { formatFinancialValue } from '@/components/FinancialValue/FinancialValue';
import { Currency } from '@/services/backer/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  heading: { marginBottom: 5 },
  icon: {
    width: 18,
    margin: '4px 5px 0 0',
  },
  details: {
    '& p': { margin: 0 },
  },
  info: {
    width: '0.6em',
    verticalAlign: 'middle',
    margin: '0 5px',
    fill: theme.palette.ink?.light,
  },
  price: {
    textAlign: 'right',
    paddingLeft: 20,
    minWidth: 60,
    '& h3': { fontWeight: 'bold' },
  },
}));

export type InsuranceSummaryProps = React.HTMLAttributes<string> & {
  is_insured: boolean;
  insurance_fee?: number;
  currency: Currency | null;
};

export default function InsuranceSummary(props: InsuranceSummaryProps) {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const { is_insured, insurance_fee = 0, currency } = props;

  return !is_insured ? null : (
    <Grid
      container
      spacing={1}
      alignItems={isNarrow ? 'flex-start' : 'center'}
      className={`${classes.root} ${props.className || ''}`}
    >
      <Grid item>
        <img
          className={classes.icon}
          alt={intl.formatMessage({ id: 'insurance.logo', defaultMessage: 'insurance icon' })}
          src="/images/icon-shield.svg"
        />
      </Grid>
      <Grid item xs className={classes.details}>
        <Typography variant="subtitle1" className={classes.heading}>
          <FormattedMessage id="insurance.heading" defaultMessage="Shipping Insurance"></FormattedMessage>
        </Typography>
        <Typography variant="caption" gutterBottom>
          {is_insured ? (
            <FormattedMessage
              id="insurance.covered"
              defaultMessage="Your order is covered from unexpected damage, loss, or theft during shipping"
            ></FormattedMessage>
          ) : (
            <FormattedMessage id="insurance.none" defaultMessage="TBD"></FormattedMessage>
          )}
        </Typography>

        <Tooltip
          interactive
          arrow
          placement="left"
          title={
            <FormattedMessage
              id="insurance.tooltip"
              defaultMessage="Easyship covers any loss or damage during shipping. Claims are simple to make and are processed within 5-7 days. <a>Learn more</a>"
              values={{
                a: (chunks: string) => (
                  <a href="https://www.easyship.com/blog/the-basics-of-shipping-insurance" rel="noopener">
                    {chunks}
                  </a>
                ),
              }}
            ></FormattedMessage>
          }
          leaveDelay={1500}
        >
          <InfoIcon className={classes.info} />
        </Tooltip>
      </Grid>
      <Grid item className={classes.price}>
        {is_insured ? (
          <Typography variant="h3">{formatFinancialValue(insurance_fee, currency)}</Typography>
        ) : (
          <Typography variant="body2">-</Typography>
        )}
      </Grid>
    </Grid>
  );
}

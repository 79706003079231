import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '30px 0',
    '& > .MuiGrid-item': { width: '100%' },
  },
  paper: {
    width: 'calc(100% - 20px)',
    maxWidth: 600,
    margin: '10px auto',
    padding: '0 20px 55px',
    textAlign: 'center',
  },
  icon: {
    marginTop: 32,
    marginBottom: 12,
  },
  text: {
    maxWidth: 320,
    margin: 'auto',
  },
}));

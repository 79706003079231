import { useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Button, Chip, Grid, Paper, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { CollectionStep, IncotermsValues, Shipment } from '@/services/backer/types';
import BackerService from '@/services/backer';
import Title from '@/components/Title';
import CollectionStepper from '@/components/CollectionStepper';
import ShipmentSummary from '@/components/ShipmentSummary';
import Footer from '@/components/Footer';
import { useTracking } from '@/providers/TrackingProvider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useStyles } from './styles';

interface IncotermsScreenProps {
  token: string;
  shipment: Shipment;
  incotermsValues: IncotermsValues;
  onError: (error: Error) => void;
  updateState: (data: Shipment) => void;
  goToAddress: () => void;
}

export default function IncotermsScreen({
  token,
  shipment,
  incotermsValues,
  onError,
  updateState,
  goToAddress,
}: IncotermsScreenProps) {
  const intl = useIntl();
  const classes = useStyles();
  const { emitEvent } = useTracking();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const flexDirection = isNarrow ? 'column' : 'row';
  const hasAddressStep = shipment.collect.show_address_option;
  const [busy, setBusy] = useState(false);

  emitEvent('Arrive - Incoterms Step');

  function handleTaxChoice({ prepayTax }: { prepayTax: boolean }): Promise<void> {
    return BackerService.setPrepayTax(token, prepayTax).then(updateState).catch(onError);
  }

  async function handleClick({ prepayTax }: { prepayTax: boolean }): Promise<void> {
    if (busy) return;

    setBusy(true);
    const incoterms = prepayTax ? 'DDP' : 'DDU';
    emitEvent(`Collect form - Incoterms - Select ${incoterms}`);

    await handleTaxChoice({ prepayTax });
    setBusy(false);
  }

  return (
    <Grid container direction="column" alignItems="center" className={classes.root}>
      <Grid item>
        <Title name={shipment.company.name} logoUrl={shipment.company.logo_url} role={shipment.user_role} />
      </Grid>
      <Grid item>
        <CollectionStepper step={CollectionStep.Incoterms} />
      </Grid>
      <Grid item>
        <Paper elevation={3} className={classes.paper}>
          <ShipmentSummary shipment={shipment} />
          <div className={classes.buttonsContainer}>
            <Grid container className={classes.marginBottomTen} wrap="nowrap">
              <Grid item>
                <img src="/images/icon-tax.svg" alt="" />
              </Grid>
              <Grid item>
                <Typography variant="h4">
                  <FormattedMessage
                    id="incoterms.select-handling-option"
                    defaultMessage="Select a handling option for Import Tax & Duty"
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction={flexDirection} wrap="nowrap" spacing={2}>
              <Grid item>
                <div onClick={() => handleClick({ prepayTax: true })} className={classes.incotermsButton}>
                  <Grid container direction="row" wrap="nowrap" className={classes.marginBottomTen}>
                    <Grid item>
                      <Chip
                        label={intl.formatMessage({ id: 'incoterms.ddp', defaultMessage: 'Pre-pay' })}
                        color="primary"
                        className={classes.chip}
                      />
                    </Grid>
                    <Grid item container direction="row" wrap="nowrap" className={classes.savings}>
                      <Grid item className={classes.marginRightFive}>
                        <Typography variant="h3">
                          <FormattedNumber
                            value={incotermsValues.ddpAmount}
                            style="currency"
                            currency={shipment.collect.display_currency as string}
                          />
                        </Typography>
                      </Grid>
                      <Grid item>
                        {incotermsValues.maxAboveDdp >= 0.005 && (
                          <Typography variant="caption">
                            <FormattedMessage
                              id="incoterms.save-up-to"
                              defaultMessage="Save up to {percentage}%"
                              values={{ percentage: Math.round(incotermsValues.maxAboveDdp * 100) }}
                            />
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Typography variant="body2">
                    <FormattedMessage
                      id="incoterms.ddp-desc"
                      defaultMessage="Pre-pay to save time and money. No further charges will be due upon the shipment's arrival in your country."
                    />
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <div onClick={() => handleClick({ prepayTax: false })} className={classes.incotermsButton}>
                  <Grid container direction="row" wrap="nowrap" className={classes.marginBottomTen}>
                    <Grid item>
                      <Chip
                        label={intl.formatMessage({ id: 'incoterms.ddu', defaultMessage: 'Pay on arrival' })}
                        color="default"
                        className={classes.chip}
                      />
                    </Grid>
                    <Grid item container direction="row" wrap="nowrap" className={classes.savings}>
                      <Grid item className={classes.marginRightFive}>
                        <Typography variant="caption">est.</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h3">
                          <FormattedNumber
                            value={incotermsValues.dduRangeMin}
                            style="currency"
                            currency={shipment.collect.display_currency as string}
                          />
                          -<FormattedNumber value={incotermsValues.dduRangeMax} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Typography variant="body2">
                    <FormattedMessage
                      id="incoterms.ddu-desc"
                      defaultMessage="Charges due upon the shipment's arrival in your country. Final cost may vary depending on the custom brokerage etc."
                    />
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
        {hasAddressStep && !busy && (
          <Grid item className={classes.buttonGroup}>
            <Button className={classes.backButton} variant="text" onClick={goToAddress} startIcon={<ArrowBackIcon />}>
              <FormattedMessage id="shipping.back" defaultMessage="Back" />
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Footer company={shipment.company} campaignUrl={shipment.collect.page_campaign_url} />
      </Grid>
    </Grid>
  );
}

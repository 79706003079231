import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    margin: '30px auto',
    maxWidth: 600,
    textAlign: 'center'
  },
  icon: {
    marginTop: 32,
    marginBottom: 20,
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 20
  },
  button: {
    marginTop: 32
  }
}));

import { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Paper, Typography } from '@material-ui/core';

import { Shipment } from '@/services/backer/types';
import Title from '@/components/Title';
import ShipmentSummary from '@/components/ShipmentSummary';
import Button from '@/components/Button';
import ContactInfoDialog from '@/components/ContactInfoDialog';
import { useTracking } from '@/providers/TrackingProvider';

import { useStyles } from './styles';

interface InactiveScreenProps {
  shipment: Shipment;
}

export default function InactiveScreen({ shipment }: InactiveScreenProps) {
  const classes = useStyles();
  const intl = useIntl();
  const { emitEvent } = useTracking();
  const [open, setOpen] = useState(false);

  emitEvent('Arrive - Inactive Campaign');

  return (
    <Grid container direction="column" alignItems="center" spacing={2} className={classes.root}>
      <Grid item>
        <Title name={shipment.company?.name} logoUrl={shipment.company?.logo_url} role={shipment.user_role} />
      </Grid>
      <Grid item>
        <Paper elevation={3} className={classes.paper}>
          <ShipmentSummary shipment={shipment} />
          <img
            src="/images/hero-too-late.svg"
            alt={intl.formatMessage({ id: 'inactive.icon', defaultMessage: 'inactive campaign icon' })}
            className={classes.logo}
          />
          <Typography variant="h1" className={classes.heading}>
            <FormattedMessage id="inactive.heading" defaultMessage="Submission Timeout"></FormattedMessage>
          </Typography>
          <Typography variant="body1" className={classes.text}>
            <FormattedMessage
              id="inactive.text"
              defaultMessage="We’re sorry to inform you that the Shipping and Tax Collection for this campaign is no longer available here. You may still contact us to arrange it separately."
            ></FormattedMessage>
          </Typography>
          {shipment.company && (
            <Fragment>
              <Button onClick={() => setOpen(true)} className={classes.button} data-testid="dialog-trigger">
                <FormattedMessage id="inactive.button" defaultMessage="Contact Us"></FormattedMessage>
              </Button>
              <ContactInfoDialog company={shipment.company} open={open} onClose={() => setOpen(false)} />
            </Fragment>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import { Scope, captureException } from '@sentry/react';

// import stripeMock from './mock';

let loadPromise: Promise<Stripe | null> | null = null;
let stripe: Stripe | null = null;
let clientSecret: string | null = null;

const load = async (publishableKey: string, token: string): Promise<Stripe | null> => {
  clientSecret = token;
  if (stripe) return Promise.resolve(stripe);

  if (loadPromise) return loadPromise;
  loadPromise = loadStripe(publishableKey);
  stripe = await loadPromise;
  return stripe;
};

const use = () => stripe;

const confirmPayment = async (cardElement: StripeCardElement, name: string): Promise<void> => {
  if (!stripe) throw new Error('Stripe error: client has not been loaded');
  if (!clientSecret) throw new Error('Stripe error: client secret is not available');
  if (!cardElement) throw new Error('Stripe error: card input element was not found');

  const confirmationOptions = {
    payment_method: {
      card: cardElement,
      billing_details: { name },
    },
  };

  const result = await stripe.confirmCardPayment(clientSecret, confirmationOptions).catch((error: Error) => {
    captureException(new Error(error.message));
    throw new Error('Payment failed, please try again.');
  });

  if (result?.error) {
    const scope = new Scope();
    scope.setTag('payment_intent', result.error.payment_intent?.id);
    scope.setTag('type', result.error.type);
    scope.setTag('code', result.error.code);
    scope.setTag('decline_code', result.error.decline_code);
    scope.setTag('param', result.error.param);
    captureException(new Error(result.error.message), scope);
    throw new Error(result.error.message);
  }

  if (result?.paymentIntent?.status !== 'succeeded') {
    throw new Error('Payment failed, but no error message was returned.');
  }
};

const StripeService = { load, use, confirmPayment };
export default StripeService;

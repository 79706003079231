import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Paper, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import StripeService from '@/services/stripe';
import { CollectionStep, CourierService, SalesTaxTerms, Shipment, StripeTokens } from '@/services/backer/types';
import Title from '@/components/Title';
import CollectionStepper from '@/components/CollectionStepper';
import ShipmentSummary from '@/components/ShipmentSummary';
import ServiceSelector from '@/components/ServiceSelector';
import IncotermsSummary from '@/components/IncotermsSummary';
import InsuranceSelector from '@/components/InsuranceSelector';
import FinancialValue from '@/components/FinancialValue';
import Footer from '@/components/Footer';
import Button from '@/components/Button';
import PaymentDialog from '@/components/PaymentDialog';
import ServiceSummary from '@/components/ServiceSummary';

import { useStyles } from './styles';
import NoRatesScreen from './NoRatesScreen';
import BackerService from '@/services/backer';
import InsuranceSummary from '@/components/InsuranceSummary';
import ClientDisclaimer from '@/components/ClientDisclaimer';
import { useTracking } from '@/providers/TrackingProvider';

interface ShippingScreenProps {
  token: string;
  shipment: Shipment;
  onError: (error: Error) => void;
  updateState: (data: Shipment) => void;
  goToAddress: () => void;
}

export default function ShippingScreen({ token, shipment, onError, updateState, goToAddress }: ShippingScreenProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const { emitEvent } = useTracking();
  const history = useHistory();
  const { collect, effective_incoterms, rates, courier_id } = shipment;
  const preselectedService = rates?.find((courier) => courier.courier_id === courier_id);
  const unpaidAmount = preselectedService?.total_charge_after_prepayment;
  const hasIncotermsStep = collect.accessible_steps.includes(CollectionStep.Incoterms);
  const hasAddressStep = shipment.collect.show_address_option;
  const [busy, setBusy] = useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [stripe, setStripe] = useState<Stripe | null>(null);

  emitEvent('Arrive - Shipping Step');

  function handleTaxSwitch(): Promise<void> {
    const prepayTax = shipment?.effective_incoterms === 'DDU';
    setBusy(true);

    return BackerService.setPrepayTax(token, prepayTax)
      .then(updateState)
      .catch(onError)
      .finally(() => setBusy(false));
  }

  function handleShippingChoice(courierId: string): Promise<void> {
    emitEvent('Edit - Courier Selection');
    setBusy(true);

    return BackerService.selectCourier(token, courierId)
      .then(updateState)
      .catch(onError)
      .finally(() => setBusy(false));
  }

  function handleInsuranceChoice(isInsured: boolean): Promise<void> {
    emitEvent('Edit - Insurance Selection', { is_insured: isInsured });
    setBusy(true);

    return BackerService.setInsurance(token, isInsured)
      .then(updateState)
      .catch(onError)
      .finally(() => setBusy(false));
  }

  async function initPayment() {
    if (!preselectedService) return;
    setBusy(true);

    try {
      const { stripe_pk, client_secret, amount, currency }: StripeTokens = await BackerService.preparePayment(
        token,
        preselectedService.total_charge_after_prepayment,
      );

      if (!stripe_pk) return;

      if (
        amount / 100 !== preselectedService.total_charge_after_prepayment ||
        currency !== collect.display_currency?.toLowerCase()
      ) {
        console.warn(
          'Mismatched payment amounts:',
          {
            total_charge_after_prepayment: preselectedService.total_charge_after_prepayment,
            display_currency: collect.display_currency,
          },
          { amount, currency },
        );
      }

      emitEvent('Collect form - Shipping - Click pay');

      const stripe = await StripeService.load(stripe_pk, client_secret);
      setStripe(stripe);
      setShowPaymentDialog(true);
    } catch (error: any) {
      onError(error);
    } finally {
      setBusy(false);
    }
  }

  function handlePayment(): Promise<void> {
    return BackerService.reportPayment(token)
      .then(updateState)
      .catch(onError)
      .finally(() => setShowPaymentDialog(false));
  }

  if (!rates || rates.length === 0) {
    return <NoRatesScreen shipment={shipment} onSwitch={handleTaxSwitch} />;
  }

  return (
    <Grid container direction="column" alignItems="center" className={classes.root}>
      <Grid item>
        <Title name={shipment.company.name} logoUrl={shipment.company.logo_url} role={shipment.user_role} />
      </Grid>
      <Grid item>
        {shipment.collect.accessible_steps[0] === CollectionStep.Incoterms && (
          <CollectionStepper step={CollectionStep.Shipping} />
        )}
      </Grid>
      <Grid item>
        <Paper elevation={3} className={classes.paper}>
          <ShipmentSummary shipment={shipment} />
          {collect.shipping_options_display !== 'hide' && rates && (
            <React.Fragment>
              {collect.shipping_options_display === 'disable' ? (
                <ServiceSummary
                  currency={collect.display_currency}
                  courierService={preselectedService as CourierService}
                  subsidy_wording={collect.subsidy_wording as string}
                  sales_tax_names={collect.sales_tax_names as SalesTaxTerms}
                  className={`${classes.row} ${isNarrow ? classes.reducePadding : classes.topRow}`}
                  optionsDisplayDisabled={true}
                />
              ) : (
                <ServiceSelector
                  currency={collect.display_currency}
                  rates={rates as CourierService[]}
                  suggestedId={courier_id as string}
                  subsidy_wording={collect.subsidy_wording as string}
                  sales_tax_names={collect.sales_tax_names as SalesTaxTerms}
                  onSelect={handleShippingChoice}
                />
              )}
              {isNarrow && (
                <Typography variant="caption" className={classes.disclaimer}>
                  <FormattedMessage
                    id="shipping.disclaimer"
                    defaultMessage="Delivery time is an estimate from the ship date"
                  />
                </Typography>
              )}
            </React.Fragment>
          )}
          {(collect.show_incoterms_option || effective_incoterms === 'DDP') && (
            <IncotermsSummary shipment={shipment} className={classes.row} />
          )}
          {collect.show_insurance_option ? (
            <InsuranceSelector
              is_insured={shipment.is_insured as boolean}
              insurance_fee={preselectedService?.insurance_fee}
              insurance_fee_not_applied={preselectedService?.insurance_fee_not_applied}
              currency={collect.display_currency}
              onToggle={handleInsuranceChoice}
              className={classes.row}
            />
          ) : (
            shipment.is_insured && (
              <InsuranceSummary
                is_insured={shipment.is_insured as boolean}
                insurance_fee={preselectedService?.insurance_fee}
                currency={collect.display_currency}
                className={classes.row}
              />
            )
          )}

          {!collect.merchant_fee_amount ? null : (
            <div className={`${classes.row} ${classes.total}`}>
              <div className={classes.merchantFeeContainer}>
                <Typography variant="subtitle2" className={classes.wordBreak}>
                  {collect.merchant_fee_title || (
                    <FormattedMessage id="global.merchant-fee" defaultMessage="Additional Fees" />
                  )}
                </Typography>
                <Typography variant="caption" className={classes.wordBreak}>
                  {collect.merchant_fee_description}
                </Typography>
              </div>

              <Typography variant="h3" component="span">
                <FinancialValue
                  value={collect.merchant_fee_amount}
                  currency={collect.merchant_fee_currency || collect.display_currency}
                />
              </Typography>
            </div>
          )}

          {!collect.prepaid_amount ? null : (
            <div className={`${classes.row} ${classes.total}`}>
              <Typography variant="subtitle2" component="span">
                {collect.prepaid_wording || <FormattedMessage id="global.prepaid" defaultMessage="Pre-paid Shipping" />}
              </Typography>
              <Typography variant="h3" component="span">
                <FinancialValue value={-collect.prepaid_amount} currency={collect.display_currency} />
              </Typography>
            </div>
          )}

          <div className={classes.total}>
            <Typography variant="h4" component="span">
              <FormattedMessage
                id="global.total"
                defaultMessage="Total ({currencyCode})"
                values={{ currencyCode: collect.display_currency }}
              />
            </Typography>
            <Typography variant="h1" component="span">
              <FinancialValue value={unpaidAmount} currency={collect.display_currency} />
            </Typography>
          </div>

          <ClientDisclaimer text={collect.page_disclaimer} url={collect.page_campaign_url} className={classes.row} />
        </Paper>
      </Grid>
      <Grid container justifyContent="space-between" className={classes.buttonsContainer}>
        <Grid item>
          {(hasIncotermsStep || hasAddressStep) && !busy && (
            <Button
              className={classes.backButton}
              variant="text"
              onClick={hasIncotermsStep ? history.goBack : goToAddress}
              startIcon={<ArrowBackIcon />}
            >
              <FormattedMessage id="shipping.back" defaultMessage="Back" />
            </Button>
          )}
        </Grid>
        <Grid item>
          <Button
            className={classes.nextButton}
            onClick={unpaidAmount ? initPayment : handlePayment}
            pending={busy}
            disabled={!preselectedService}
            endIcon={<ArrowForwardIcon />}
          >
            <FormattedMessage
              id={unpaidAmount ? 'shipping.confirm' : 'shipping.pay'}
              defaultMessage={unpaidAmount ? 'Confirm' : 'Pay Now'}
            />
          </Button>
          {stripe && (
            <Elements stripe={stripe}>
              <PaymentDialog
                open={showPaymentDialog}
                shipment={shipment}
                onClose={() => setShowPaymentDialog(false)}
                onPayment={handlePayment}
              />
            </Elements>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Footer company={shipment.company} terms="full" postPayment={!unpaidAmount} />
      </Grid>
    </Grid>
  );
}

import { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Chip, FormControlLabel, Grid, Radio, TableRow, TableCell, Typography } from '@material-ui/core';

import FinancialValue from '@/components/FinancialValue';
import { CourierService, Currency } from '@/services/backer/types';

const useStyles = makeStyles((theme: Theme) => ({
  alignTop: {
    '& .MuiTableCell-head': {
      verticalAlign: 'text-top',
      paddingTop: 24,
      paddingBottom: 8,

      '& .MuiTypography-root': { lineHeight: '1em' },
    },
  },
  radioLabel: { flexWrap: 'nowrap' },
  chipContainer: {
    '& div:first-child': {
      marginRight: 2,
    },

    '& div:not(:first-child)': {
      margin: '0 2px',
    },
  },
  chip: {
    backgroundColor: theme.palette.teal?.main,
  },
  icon: {
    width: 40,
    marginTop: 4,
    borderRadius: 2,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '8px 0 !important',
    '& p': { margin: 0 },
  },
  selected: {
    '& span': {
      color: `${theme.palette.ink?.dark} !important`,
    },
  },
  subsidyWording: {
    color: theme.palette.ink?.light,
  },
  subsidyWordingContainer: {
    width: '100%',
  },
  wordBreak: {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  cost: {
    fontWeight: 'bold',
    color: theme.palette.ink?.light,
  },
}));

export interface ServiceSelectorRowProps {
  currency: Currency | null;
  courierService: CourierService;
  selected: boolean;
  subsidyWording: string;
  salesTaxNames?: string;
  cheapestIsFree: boolean;
  disableRankChips: boolean;
}

export default function WideRow(props: ServiceSelectorRowProps) {
  const classes = useStyles(props);
  const intl = useIntl();
  const { currency, courierService, subsidyWording, salesTaxNames, selected, cheapestIsFree, disableRankChips } = props;
  const {
    courier_id,
    courier_display_name,
    courier_logo,
    min_delivery_time,
    max_delivery_time,
    cost_rank,
    delivery_time_rank,
    value_for_money_rank,
    subsidy_applied,
    base_charge_after_subsidy,
  } = courierService;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <FormControlLabel
          value={courier_id}
          control={<Radio color="default" name="courier-service" />}
          label={
            <Grid container spacing={2} className={classes.radioLabel}>
              <Grid item>
                <img
                  className={classes.icon}
                  alt={courier_display_name}
                  src={`https://assets.easyship.com/app/courier-logos/${courier_logo}-mini.svg`}
                />
              </Grid>
              <Grid item className={classes.details}>
                {!disableRankChips && (
                  <div className={classes.chipContainer}>
                    {cost_rank === 1 && (
                      <Chip
                        label={intl.formatMessage({
                          id: 'courier-service.cheapest',
                          defaultMessage: 'Cheapest',
                        })}
                        color="primary"
                        className={classes.chip}
                      />
                    )}
                    {delivery_time_rank === 1 && (
                      <Chip
                        label={intl.formatMessage({
                          id: 'courier-service.fastest',
                          defaultMessage: 'Fastest',
                        })}
                        color="primary"
                        className={classes.chip}
                      />
                    )}
                    {value_for_money_rank === 1 && (
                      <Chip
                        label={intl.formatMessage({
                          id: 'courier-service.best-value',
                          defaultMessage: 'Best Value',
                        })}
                        color="primary"
                        className={classes.chip}
                      />
                    )}
                  </div>
                )}
                <Typography gutterBottom variant="body1">
                  {courier_display_name}
                </Typography>
              </Grid>
            </Grid>
          }
        />
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">
          <FormattedMessage
            id="courier-service.delivery-range-short"
            defaultMessage="{min_delivery_time} - {max_delivery_time} working days"
            values={
              min_delivery_time + max_delivery_time
                ? { min_delivery_time, max_delivery_time }
                : { min_delivery_time: 29, max_delivery_time: 36 }
            }
          ></FormattedMessage>
        </Typography>
      </TableCell>
      <TableCell align="right" className={selected ? classes.selected : ''}>
        {base_charge_after_subsidy === 0 ? (
          <div className={classes.subsidyWordingContainer}>
            <Typography variant="subtitle1" component="span" className={`${classes.cost} ${classes.wordBreak}`}>
              {subsidyWording || <FormattedMessage id="courier-service.paid" defaultMessage="Paid"></FormattedMessage>}
            </Typography>
          </div>
        ) : (
          <Fragment>
            {subsidy_applied && cheapestIsFree && (
              <Typography variant="body2" component="span" className={classes.subsidyWording}>
                <FormattedMessage id="courier-service.add" defaultMessage="Add "></FormattedMessage>
              </Typography>
            )}
            <Typography variant="h3" component="span" className={classes.cost}>
              <FinancialValue value={base_charge_after_subsidy} currency={currency} />
            </Typography>
            {salesTaxNames && (
              <Typography variant="caption" component="p">
                {salesTaxNames}
              </Typography>
            )}
          </Fragment>
        )}
      </TableCell>
    </TableRow>
  );
}

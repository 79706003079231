import { Address, Shipment } from 'services/backer/types';
import { useState } from 'react';
import { useStyles } from './styles';
import AddressForm from './AddressForm';
import Title from 'components/Title';
import AddressConfirmation from './AddressConfirmation';
import { FormAddress } from '@/services/address/types';
import AddressResource from '@/services/address/resource';
import BackerService from '@/services/backer';
import ErrorScreen from '../ErrorScreen';
import Footer from '@/components/Footer';

interface AddressScreenProps {
  shipment: Shipment;
  token: string;
  onAddressAccepted: (data: Shipment) => void;
}

export default function AddressScreen({ shipment, token, onAddressAccepted }: AddressScreenProps) {
  const isAddressConfirmed = shipment.address_confirmed;
  const isAddressValid = shipment.address_valid === undefined || shipment.address_valid;
  const [isFormVisible, setIsFormVisible] = useState(isAddressConfirmed || !isAddressValid);
  const [hasError, setHasError] = useState(false);

  const classes = useStyles();

  const toAddressForm = (address: Address) => {
    return {
      name: address.name || '',
      address_line_1: address.address_line_1 || '',
      address_line_2: address.address_line_2 || '',
      state: address.state || '',
      city: address.city || '',
      postal_code: address.postal_code && address.postal_code !== '0' ? address.postal_code : '',
      country_alpha2: address.country.alpha2 || '',
      phone_number: address.phone_number || '',
      consignee_tax_id: address.consignee_tax_id || '',
    };
  };

  const handleAddressSubmit = async (data: FormAddress) => {
    const res = await AddressResource.updateAddress(token, data);
    res.address.has_rates ? BackerService.get(token).then(onAddressAccepted) : onAddressRejected();
  };

  const handleAddressConfirm = async () => {
    const res = await AddressResource.confirmAddress(token);
    !res.errors ? BackerService.get(token).then(onAddressAccepted) : onAddressRejected();
  };

  const onAddressRejected = () => {
    setIsFormVisible(false);
    setHasError(true);
  };

  const closeErrorPage = () => {
    BackerService.get(token)
      .then(onAddressAccepted)
      .then(() => {
        setHasError(false);
        setIsFormVisible(true);
      });
  };

  if (hasError) return <ErrorScreen shipment={shipment} goBack={closeErrorPage} />;

  return (
    <div className={classes.root}>
      <Title name={shipment.company.name} logoUrl={shipment.company.logo_url} role={shipment.user_role} />
      {isFormVisible ? (
        <AddressForm
          address={toAddressForm(shipment.destination_address)}
          onSubmit={handleAddressSubmit}
          showBackButton={isAddressValid}
          goBack={() => setIsFormVisible(false)}
          originAddressAlpha={shipment.origin_address.country.alpha2}
        />
      ) : (
        <AddressConfirmation
          address={shipment.destination_address}
          onChangeClick={() => setIsFormVisible(true)}
          onConfirmClick={handleAddressConfirm}
        />
      )}
      <Footer company={shipment.company} campaignUrl={shipment.collect.page_campaign_url} />
    </div>
  );
}

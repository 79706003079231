import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';
import DOMPurify from 'dompurify';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    textAlign: 'center',
    padding: 10,
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
}));

export type ClientDisclaimerProps = React.HTMLAttributes<string> & {
  text?: string | null;
  url?: string | null;
  className?: string;
};

export default function ClientDisclaimer({ text, url }: ClientDisclaimerProps) {
  const classes = useStyles();
  const html = DOMPurify.sanitize(text || '', { ADD_ATTR: ['target'] });
  const addHyperlink = (chunk: string) => (
    <a href={url || '#'} target="_blank" rel="noopener noreferrer" className={classes.link}>
      {chunk}
    </a>
  );

  return (
    <Fragment>
      <Typography dangerouslySetInnerHTML={{ __html: html }} className={classes.text} />

      {url && (
        <Typography variant="body1" className={classes.text}>
          <FormattedMessage
            id="disclaimer.visit-page"
            defaultMessage="For more info, please <a>visit our page</a>."
            values={{ a: addHyperlink }}
          ></FormattedMessage>
        </Typography>
      )}
    </Fragment>
  );
}

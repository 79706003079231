import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useStyles } from './styles';
import Button from 'components/Button';
import { useState } from 'react';
import { FormAddress, FormCountry } from '@/services/address/types';
import { FormInput } from '@/components/FormInput';
import CountryAutocomplete from '@/components/CountryAutocomplete';
import AddressResource from '@/services/address/resource';
import useDidUpdate from '@/hooks/useDidUpdate';
import { useCountries } from '@/hooks/useCountries';

interface AddressFormProps {
  address: FormAddress;
  onSubmit: (data: FormAddress) => Promise<void>;
  showBackButton: boolean;
  goBack: () => void;
  originAddressAlpha: string;
}

export default function AddressForm({
  address,
  onSubmit,
  showBackButton,
  goBack,
  originAddressAlpha,
}: AddressFormProps) {
  const classes = useStyles();
  const [addressData, setAddressData] = useState<FormAddress>(address);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Partial<Record<keyof FormAddress, string>>>({});
  const countriesWithRequiredState = ['US', 'AU', 'CA', 'MX', 'TH', 'CN', 'ID', 'MY', 'VN'];
  const { countries, validatePostalCode } = useCountries();
  const currentCountry = countries.find((country) => country.alpha2 === addressData.country_alpha2);
  const isTaxIdRequired = (originAddressAlpha !== addressData.country_alpha2 && currentCountry?.taxIdRequired) ?? false;

  const handleChange = (updatedData: Partial<FormAddress>) => {
    setAddressData((prevState) => {
      return { ...prevState, ...updatedData };
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const hasErrors = Object.keys(errors).length !== 0;

    if (!hasErrors) {
      setLoading(true);
      onSubmit(addressData).finally(() => setLoading(false));
    }
  };

  const handleCountryChange = (_: React.ChangeEvent<{}>, country: FormCountry) => {
    handleChange({ country_alpha2: country.alpha2, postal_code: '', state: '', city: '' });
  };

  const handlePostalCodeChange = (postalCode: string) => {
    handleChange({ postal_code: postalCode });
    const isValid = postalCode ? validatePostalCode(addressData.country_alpha2, postalCode) : true;
    updateErrors('postal_code', !isValid);
  };

  const handlePhoneNumberChange = (data: string) => {
    handleChange({ phone_number: data });
    const hasErrors = data ? !data.match('^(\\+?[\\d\\s\\-.()#]|(ext))+$') : false;
    updateErrors('phone_number', hasErrors);
  };

  const updateStateAndCity = async () => {
    if (addressData.postal_code && addressData.country_alpha2 === 'US') {
      const res = await AddressResource.getCityAndState(addressData.postal_code);
      handleChange({ state: res.state.name, city: res.city });
    }
  };

  useDidUpdate(updateStateAndCity, [addressData.postal_code], 1000);

  const updateErrors = (type: keyof FormAddress, hasError: boolean) => {
    const timeOutFunction = setTimeout(() => {
      if (hasError) {
        setErrors((prevState) => ({ ...prevState, [type]: 'Incorrect format.' }));
      } else {
        delete errors[type];
        setErrors({ ...errors });
      }
    }, 500);
    return () => clearTimeout(timeOutFunction);
  };

  return (
    <>
      <Typography align="center" variant="h2" className={classes.title}>
        <FormattedMessage id="address.save" defaultMessage="Shipping Address" />
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container className={classes.grid}>
          <Grid item xs={6}>
            <FormInput
              value={addressData.name}
              label="Name"
              onChange={(e) => handleChange({ name: e.target.value })}
              maxLength={50}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              value={addressData.phone_number}
              id="phone_number"
              label="Phone Number"
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
              maxLength={50}
              error={errors.hasOwnProperty('phone_number')}
              helperText={errors.phone_number || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <CountryAutocomplete value={addressData.country_alpha2} onChange={handleCountryChange} />
          </Grid>
          {addressData.country_alpha2 !== 'HK' && (
            <Grid item xs={6}>
              <FormInput
                value={addressData.postal_code}
                label={addressData.country_alpha2 === 'US' ? 'Zip code' : 'Postal code'}
                onChange={(e) => handlePostalCodeChange(e.target.value)}
                maxLength={20}
                error={errors.hasOwnProperty('postal_code')}
                helperText={errors.postal_code || ''}
              />
            </Grid>
          )}
          {addressData.country_alpha2 !== 'HK' && (
            <Grid item xs={6}>
              <FormInput
                value={addressData.state}
                label="State/Province"
                onChange={(e) => handleChange({ state: e.target.value })}
                optional={!countriesWithRequiredState.includes(addressData.country_alpha2)}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <FormInput
              value={addressData.city}
              label={addressData.country_alpha2 === 'AU' ? 'Suburb' : 'City'}
              onChange={(e) => handleChange({ city: e.target.value })}
            />
          </Grid>

          <Grid item xs={12}>
            <FormInput
              value={addressData.address_line_1}
              label="Address Line 1"
              onChange={(e) => handleChange({ address_line_1: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              value={addressData.address_line_2}
              label="Address Line 2"
              onChange={(e) => handleChange({ address_line_2: e.target.value })}
              optional
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              value={addressData.consignee_tax_id}
              label="Tax ID"
              onChange={(e) => handleChange({ consignee_tax_id: e.target.value })}
              optional={!isTaxIdRequired}
            />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.footer}>
              {showBackButton && (
                <Button variant="text" className={classes.textButton} onClick={goBack} startIcon={<ArrowBackIcon />}>
                  <FormattedMessage id="address.back" defaultMessage="Back" />
                </Button>
              )}
              <Button className={classes.button} type="submit" fullWidth={!showBackButton} disabled={loading}>
                <>
                  {loading && <CircularProgress size={24} />}
                  {!loading && <FormattedMessage id="address.save" defaultMessage="Save changes" />}
                </>
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

import { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Chip, FormControlLabel, Grid, Radio, TableRow, TableCell, Typography, Box } from '@material-ui/core';

import FinancialValue from '@/components/FinancialValue';
import { CourierService, Currency } from '@/services/backer/types';

const useStyles = makeStyles((theme: Theme) => ({
  left: {
    padding: '0 !important',
    maxWidth: 0,
  },
  radioLabel: {
    margin: 0,
    flexWrap: 'nowrap',
    width: '100%',
  },
  innerLabel: {
    width: 'calc(100% - 90px)',
    padding: '15px 0',
  },
  chipContainer: {
    marginBottom: 3,
    width: 190,
  },
  chip: {
    backgroundColor: theme.palette.teal?.main,
    margin: '0 2px',
    '&:first-of-type': { marginLeft: 0 },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 30,
    marginRight: 4,
    borderRadius: 2,
  },
  name: {
    width: 155,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'break-word',
  },
  deliveryTime: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
  },
  right: {
    width: 140,
    paddingRight: '15px !important',
  },
  selected: {
    '& span': {
      color: `${theme.palette.ink?.dark} !important`,
    },
  },
  subsidyWording: {
    color: theme.palette.ink?.light,
  },
  subsidyWordingContainer: {
    width: '100%',
  },
  wordBreak: {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  cost: {
    fontWeight: 'bold',
    color: theme.palette.ink?.light,
  },
}));

export interface ServiceSelectorRowProps {
  currency: Currency | null;
  courierService: CourierService;
  selected: boolean;
  subsidyWording: string;
  salesTaxNames?: string;
  cheapestIsFree: boolean;
  disableRankChips: boolean;
}

export default function NarrowRow(props: ServiceSelectorRowProps) {
  const classes = useStyles(props);
  const intl = useIntl();
  const { currency, courierService, subsidyWording, salesTaxNames, selected, cheapestIsFree, disableRankChips } = props;
  const {
    courier_id,
    courier_display_name,
    courier_logo,
    min_delivery_time,
    max_delivery_time,
    cost_rank,
    delivery_time_rank,
    value_for_money_rank,
    subsidy_applied,
    base_charge_after_subsidy,
  } = courierService;

  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.left}>
        <FormControlLabel
          value={courier_id}
          className={classes.radioLabel}
          control={<Radio color="default" name="courier-service" />}
          label={
            <Grid container direction="column" className={classes.innerLabel}>
              {(cost_rank === 1 || delivery_time_rank === 1 || value_for_money_rank === 1) && !disableRankChips && (
                <Grid item className={classes.chipContainer}>
                  {cost_rank === 1 && (
                    <Chip
                      label={intl.formatMessage({
                        id: 'courier-service.cheapest',
                        defaultMessage: 'Cheapest',
                      })}
                      color="primary"
                      className={classes.chip}
                    />
                  )}
                  {delivery_time_rank === 1 && (
                    <Chip
                      label={intl.formatMessage({
                        id: 'courier-service.fastest',
                        defaultMessage: 'Fastest',
                      })}
                      color="primary"
                      className={classes.chip}
                    />
                  )}
                  {value_for_money_rank === 1 && (
                    <Chip
                      label={intl.formatMessage({
                        id: 'courier-service.best-value',
                        defaultMessage: 'Best Value',
                      })}
                      color="primary"
                      className={classes.chip}
                    />
                  )}
                </Grid>
              )}
              <Grid item container alignItems="center" direction="row" wrap="nowrap">
                <Grid item className={classes.iconContainer}>
                  <img
                    className={classes.icon}
                    alt={courier_display_name}
                    src={`https://assets.easyship.com/app/courier-logos/${courier_logo}-mini.svg`}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body1" component="p" className={classes.name}>
                    {courier_display_name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </TableCell>

      <TableCell align="right" className={`${classes.right} ${selected ? classes.selected : ''}`}>
        <div>
          {base_charge_after_subsidy === 0 ? (
            <Box width="100%">
              <Typography variant="subtitle1" component="span" className={`${classes.cost} ${classes.wordBreak}`}>
                {subsidyWording || (
                  <FormattedMessage id="courier-service.paid" defaultMessage="Paid"></FormattedMessage>
                )}
              </Typography>
            </Box>
          ) : (
            <Fragment>
              {subsidy_applied && cheapestIsFree && (
                <Typography variant="body2" component="span" className={classes.subsidyWording}>
                  <FormattedMessage id="courier-service.add" defaultMessage="Add "></FormattedMessage>
                </Typography>
              )}
              <Typography variant="h3" component="span" className={classes.cost}>
                <FinancialValue value={base_charge_after_subsidy} currency={currency} />
              </Typography>
              {salesTaxNames && (
                <Typography variant="caption" component="p">
                  {salesTaxNames}
                </Typography>
              )}
            </Fragment>
          )}
        </div>
        <Typography variant="body2" className={classes.deliveryTime}>
          <FormattedMessage
            id="courier-service.delivery-range-short"
            defaultMessage="{min_delivery_time} - {max_delivery_time} working days"
            values={
              min_delivery_time + max_delivery_time
                ? { min_delivery_time, max_delivery_time }
                : { min_delivery_time: 29, max_delivery_time: 36 }
            }
          ></FormattedMessage>
        </Typography>
      </TableCell>
    </TableRow>
  );
}

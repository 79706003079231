import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Dialog, DialogTitle, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import {
  Close as CloseIcon,
  Computer as ComputerIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
} from '@material-ui/icons';

import { Company } from '@/services/backer/types';
import { useTracking } from '@/providers/TrackingProvider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 15,
    textAlign: 'center',
  },
  paper: {
    minWidth: 320,
  },
  link: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  listItem: {
    '& .MuiListItemAvatar-root': {
      minWidth: 32,
      '& .MuiSvgIcon-root': {
        width: '0.6em',
        margin: '-10px -10px 0 0',
        fill: 'rgb(197, 205, 212)',
      },
    },

    '& .MuiListItemText-secondary': {
      fontWeight: 'bold',
    },
  },
}));

export interface ContactInfoDialogProps {
  company: Company;
  open: boolean;
  onClose: () => void;
}

export default function ContactInfoDialog({ company, open, onClose }: ContactInfoDialogProps) {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const { emitEvent } = useTracking();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const { cs_url, contact_email, phone_number, facebook_url, twitter_url, instagram_url, youtube_url } = company;

  const RE_PROTOCOL = /^http/;
  const protocolChecker = (url: string) => (RE_PROTOCOL.test(url) ? url : `//${url}`);

  return (
    <Dialog
      aria-labelledby="contact options"
      fullScreen={isNarrow}
      classes={{ paper: classes.paper }}
      open={open}
      onClose={onClose}
      TransitionProps={{ onEnter: () => emitEvent('Collect form - Contact us') }}
    >
      <DialogTitle data-testid="dialog-title">
        <FormattedMessage id="contact.title" defaultMessage="Contact Us"></FormattedMessage>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <List>
        {cs_url && (
          <ListItem
            button
            component="a"
            href={protocolChecker(cs_url)}
            rel="noopener"
            className={classes.listItem}
            data-testid="button-cs"
          >
            <ListItemAvatar>
              <ComputerIcon />
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({ id: 'contact.website', defaultMessage: 'Website:' })}
              secondary={cs_url}
            />
          </ListItem>
        )}
        {contact_email && (
          <ListItem
            button
            component="a"
            href={`mailto:${contact_email}`}
            rel="noopener noreferrer"
            className={classes.listItem}
            data-testid="button-email"
          >
            <ListItemAvatar>
              <EmailIcon />
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({ id: 'contact.email', defaultMessage: 'Email:' })}
              secondary={contact_email}
            />
          </ListItem>
        )}
        {phone_number && (
          <ListItem
            button
            component="a"
            href={`tel:${phone_number}`}
            rel="noopener noreferrer"
            className={classes.listItem}
            data-testid="button-phone"
          >
            <ListItemAvatar>
              <PhoneIcon />
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({ id: 'contact.phone', defaultMessage: 'Contact Number:' })}
              secondary={phone_number}
            />
          </ListItem>
        )}
        {facebook_url && (
          <ListItem
            button
            component="a"
            href={protocolChecker(facebook_url)}
            rel="noopener noreferrer"
            className={classes.listItem}
            data-testid="button-facebook"
          >
            <ListItemAvatar>
              <FacebookIcon />
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({ id: 'contact.facebook', defaultMessage: 'Facebook:' })}
              secondary={facebook_url}
            />
          </ListItem>
        )}
        {twitter_url && (
          <ListItem
            button
            component="a"
            href={protocolChecker(twitter_url)}
            rel="noopener noreferrer"
            className={classes.listItem}
            data-testid="button-twitter"
          >
            <ListItemAvatar>
              <TwitterIcon />
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({ id: 'contact.twitter', defaultMessage: 'Twitter:' })}
              secondary={twitter_url}
            />
          </ListItem>
        )}
        {instagram_url && (
          <ListItem
            button
            component="a"
            href={protocolChecker(instagram_url)}
            rel="noopener noreferrer"
            className={classes.listItem}
            data-testid="button-instagram"
          >
            <ListItemAvatar>
              <InstagramIcon />
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({ id: 'contact.instagram', defaultMessage: 'Instagram:' })}
              secondary={instagram_url}
            />
          </ListItem>
        )}
        {youtube_url && (
          <ListItem
            button
            component="a"
            href={protocolChecker(youtube_url)}
            rel="noopener noreferrer"
            className={classes.listItem}
            data-testid="button-youtube"
          >
            <ListItemAvatar>
              <YouTubeIcon />
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({ id: 'contact.youtube', defaultMessage: 'YouTube:' })}
              secondary={youtube_url}
            />
          </ListItem>
        )}
      </List>
    </Dialog>
  );
}

import { FormattedMessage } from 'react-intl';
import { Grid, Paper, Typography } from '@material-ui/core';

import { CollectionStep, Shipment } from '@/services/backer/types';
import Title from '@/components/Title';
import CollectionStepper from '@/components/CollectionStepper';
import ShipmentSummary from '@/components/ShipmentSummary';
import Footer from '@/components/Footer';
import Button from '@/components/Button';

import { useStyles } from './styles';

interface NoRatesScreenProps {
  shipment: Shipment;
  onSwitch: () => Promise<void>;
}

export default function ShippingScreen({ shipment, onSwitch }: NoRatesScreenProps) {
  const classes = useStyles();
  const { company, effective_incoterms } = shipment;

  return (
    <Grid container direction="column" alignItems="center" spacing={2} className={classes.root}>
      <Grid item>
        <Title name={company.name} logoUrl={company.logo_url} role={shipment.user_role} />
      </Grid>
      <Grid item>
        <CollectionStepper step={CollectionStep.Incoterms} />
      </Grid>
      <Grid item>
        <Paper elevation={3} className={classes.paper}>
          <ShipmentSummary shipment={shipment} />
          <Typography variant="h2" className={classes.description}>
            <FormattedMessage
              id="incoterms.no-rates"
              defaultMessage="Sorry. We couldn't find any shipping services that allow you to {effective_incoterms, select, DDP{pre-pay} other{pay}} taxes and duty{effective_incoterms, select, DDP{.} other{ upon delivery.}} Please click below to find other options."
              values={{ effective_incoterms }}
            />
          </Typography>
          <Button className={classes.button} onClick={onSwitch}>
            <FormattedMessage id="incoterms.button-switch" defaultMessage="See other shipping options" />
          </Button>
        </Paper>
      </Grid>
      <Grid item>
        <Footer company={shipment.company} />
      </Grid>
    </Grid>
  );
}

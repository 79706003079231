import handleError from '@/services/helpers/handleError';

import { CollectionPayload, IBackerResource, PaymentIntentPayload, Shipment, StripeTokens } from './types';

const API_PATH = `${import.meta.env.VITE_API_BASE}/v1/backer/shipment_collect`;
const HEADERS = { 'Content-Type': 'application/json' };

type ResourceType = 'step' | 'recalculate' | 'payment_intent' | 'confirm';
type APIMethodType = 'GET' | 'POST' | 'PUT' | 'DELETE';

async function fetchShipmentCollectWithToken(
  token: string,
  resourceType: ResourceType,
  method: APIMethodType = 'GET',
  payload?: object,
) {
  let queryString = '';
  let options: RequestInit | undefined;

  if (method === 'GET') {
    queryString = '?' + new URLSearchParams({ token });
  } else {
    options = {
      method,
      headers: { ...HEADERS, 'jwe-token': token },
      body: payload && JSON.stringify(payload),
    };
  }

  const res = await fetch(`${API_PATH}/${resourceType}${queryString}`, options);
  return res.ok ? res.json() : handleError(res);
}

function step(token: string): Promise<Shipment> {
  return fetchShipmentCollectWithToken(token, 'step');
}

function recalculate(token: string, payload: CollectionPayload): Promise<Shipment> {
  return fetchShipmentCollectWithToken(token, 'recalculate', 'POST', payload);
}

function paymentIntent(token: string, payload: PaymentIntentPayload): Promise<StripeTokens> {
  return fetchShipmentCollectWithToken(token, 'payment_intent', 'POST', payload);
}

function confirm(token: string): Promise<Shipment> {
  return fetchShipmentCollectWithToken(token, 'confirm', 'POST');
}

const BackerResource: IBackerResource = {
  step,
  recalculate,
  paymentIntent,
  confirm,
};

export default BackerResource;

import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { CollectionStep } from '@/services/backer/types';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '95vw',
    padding: 8,
    paddingTop: 4,

    '& .MuiStepConnector-horizontal': { width: 20 },
  },
}));

export type CollectionStepperProps = {
  step: CollectionStep;
};

export default function CollectionStepper(props: CollectionStepperProps) {
  const classes = useStyles();
  const activeStep = [CollectionStep.Incoterms, CollectionStep.Shipping, CollectionStep.Success].indexOf(props.step);

  return (
    <Stepper activeStep={activeStep} className={classes.root}>
      <Step>
        <StepLabel>
          <FormattedMessage id="stepper.incoterms" defaultMessage="Tax & Duty Handling"></FormattedMessage>
        </StepLabel>
        ]
      </Step>
      <Step>
        <StepLabel>
          <FormattedMessage id="stepper.shipping" defaultMessage="Shipping"></FormattedMessage>
        </StepLabel>
      </Step>
    </Stepper>
  );
}

import { makeStyles } from '@material-ui/core/styles';
import COLORS from '@/config/colors';

export const useStyles = makeStyles({
  root: {
    padding: '30px 0',
  },
  paper: {
    maxWidth: 620,
    margin: 10,
  },
  buttonsContainer: {
    padding: '20px 22px 40px',
    '& img': { margin: '0 10px 0 2px' },
    '& h4': { fontWeight: 'bold', marginTop: 2, fontSize: 13 },
  },
  button: {
    margin: 15,
    '& .MuiButton-endIcon': { marginTop: -2 },
  },
  secondary: {
    textTransform: 'none',
    padding: '8px 16px',
  },
  incotermsButton: {
    position: 'relative',
    height: '100%',
    border: `1px solid ${COLORS.skyDark}`,
    borderRadius: 8,
    padding: '10px 15px',

    '& h3': { fontWeight: 'bold' },

    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 5px 15px #284E6629',
      bottom: 3,
    },
  },
  buttonGroup: {
    padding: '0 10px',
  },
  backButton: {
    margin: '20px 0',
    padding: 10,
    width: 80,
  },
  marginBottomTen: {
    marginBottom: 10,
  },
  marginRightFive: {
    marginRight: 5,
  },
  chip: {
    marginRight: 5,
    color: COLORS.white,
  },
  savings: {
    marginTop: 4,
  },
  italic: {
    fontStyle: 'italic',
  },
});

import * as Sentry from '@sentry/react';

import { IBackerService, Incoterms, Shipment, StripeTokens } from './types';
import BackerResource from './resource';

function get(token: string): Promise<Shipment> {
  return BackerResource.step(token).then((shipment: Shipment) => {
    Sentry.setContext('shipment_collect', {
      id: shipment.id,
      show_incoterms_option: shipment.collect.show_incoterms_option,
      show_insurance_option: shipment.collect.show_insurance_option,
      current_step: shipment.collect.current_step,
    });

    return shipment;
  });
}

function setPrepayTax(token: string, prepayTax: boolean): Promise<Shipment> {
  const incoterms = prepayTax ? Incoterms.DDP : Incoterms.DDU;
  return BackerResource.recalculate(token, { incoterms });
}

function selectCourier(token: string, courierId: string): Promise<Shipment> {
  return BackerResource.recalculate(token, { courier_id: courierId });
}

function setInsurance(token: string, isInsured: boolean): Promise<Shipment> {
  return BackerResource.recalculate(token, { is_insured: isInsured });
}

function preparePayment(token: string, totalChargeAfterPrepayment: number): Promise<StripeTokens> {
  return BackerResource.paymentIntent(token, { total_charge_after_prepayment: totalChargeAfterPrepayment });
}

function reportPayment(token: string): Promise<Shipment> {
  return BackerResource.confirm(token).then((shipment: Shipment) => {
    shipment.collect.paymentReported = true;
    return shipment;
  });
}

const BackerService: IBackerService = {
  get,
  setPrepayTax,
  selectCourier,
  setInsurance,
  preparePayment,
  reportPayment,
};

export default BackerService;

import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    margin: '30px 0',
  },
  heading: { marginTop: 10 },
}));

interface ThankYouProps {
  className?: string;
}

export default function ThankYou(props: ThankYouProps) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Container className={`${classes.root} ${props.className || ''}`}>
      <img
        src="/images/hero-check.svg"
        alt={intl.formatMessage({ id: 'thank-you.logo', defaultMessage: 'check mark icon' })}
      />
      <Typography variant="h1" className={classes.heading}>
        <FormattedMessage id="thank-you.heading" defaultMessage="Thank you!"></FormattedMessage>
      </Typography>
      <Typography variant="body1">
        <FormattedMessage id="thank-you.text" defaultMessage="Your order will be shipped out soon."></FormattedMessage>
      </Typography>
    </Container>
  );
}

import { Fragment } from 'react';
import { Currency } from '@/services/backer/types';

const locale = 'en';

export interface FinancialValueProps {
  value?: number | null;
  currency?: Currency | null;
  currencyDisplay?: 'symbol' | 'code' | 'name';
}

export const formatFinancialValue = (
  value: number | null = 0,
  currency?: Currency | null,
  currencyDisplay?: 'symbol' | 'code' | 'name',
) => {
  if (!currency) return value ? value.toFixed(2) : '-';

  return value ? value.toLocaleString(locale, { style: 'currency', currency, currencyDisplay }) : '-';
};

export default function FinancialValue(props: FinancialValueProps) {
  const { value, currency, currencyDisplay } = props;

  return <Fragment>{formatFinancialValue(value, currency, currencyDisplay)}</Fragment>;
}

import { FormattedMessage } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Paper, Typography } from '@material-ui/core';

import { CollectionStep, CourierService, Shipment } from '@/services/backer/types';
import Title from '@/components/Title';
import CollectionStepper from '@/components/CollectionStepper';
import ShipmentSummary from '@/components/ShipmentSummary';
import ServiceSummary from '@/components/ServiceSummary';
import IncotermsSummary from '@/components/IncotermsSummary';
import InsuranceSummary from '@/components/InsuranceSummary';
import FinancialValue from '@/components/FinancialValue';
import Footer from '@/components/Footer';

import { useStyles } from './styles';
import ThankYou from '@/components/ThankYou';
import ClientDisclaimer from '@/components/ClientDisclaimer';
import { useTracking } from '@/providers/TrackingProvider';

interface SuccessScreenProps {
  shipment: Shipment;
}

export default function SuccessScreen({ shipment }: SuccessScreenProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const { emitEvent } = useTracking();

  emitEvent('Arrive - Success Step');

  const { collect, shipment_collect } = shipment;

  return (
    <Grid container direction="column" alignItems="center" spacing={2} className={classes.root}>
      <Grid item>
        <Title name={shipment.company.name} logoUrl={shipment.company.logo_url} role={shipment.user_role} />
      </Grid>
      <Grid item>
        {shipment.collect.accessible_steps.length > 2 && <CollectionStepper step={CollectionStep.Shipping} />}
      </Grid>
      <Grid item>
        <Paper elevation={3} className={classes.paper}>
          {collect.paymentReported && isNarrow && <ThankYou className={classes.topThanks} />}
          <ShipmentSummary shipment={shipment} />
          {collect.paymentReported && !isNarrow && <ThankYou />}
          <ServiceSummary
            currency={collect.display_currency}
            courierService={shipment_collect as CourierService}
            subsidy_wording={collect.subsidy_wording as string}
            sales_tax_names={collect.sales_tax_names}
            className={`${classes.row} ${isNarrow ? classes.reducePadding : classes.topRow}`}
          />
          <IncotermsSummary shipment={shipment} className={classes.row} />
          <InsuranceSummary
            is_insured={shipment.is_insured as boolean}
            insurance_fee={shipment_collect?.insurance_fee as number}
            currency={collect.display_currency}
            className={classes.row}
          />

          {!collect.merchant_fee_amount ? null : (
            <div className={`${classes.row} ${classes.total}`}>
              <div className={classes.merchantFeeContainer}>
                <Typography variant="subtitle2" className={classes.wordBreak}>
                  {collect.merchant_fee_title || (
                    <FormattedMessage id="global.merchant-fee" defaultMessage="Other fees charged by Merchant" />
                  )}
                </Typography>
                <Typography variant="caption" className={classes.wordBreak}>
                  {collect.merchant_fee_description}
                </Typography>
              </div>

              <Typography variant="h3" component="span">
                <FinancialValue
                  value={collect.merchant_fee_amount}
                  currency={collect.merchant_fee_currency || collect.display_currency}
                />
              </Typography>
            </div>
          )}

          {!collect.prepaid_amount ? null : (
            <div className={`${classes.row} ${classes.total}`}>
              <Typography variant="subtitle2" component="span">
                {collect.prepaid_wording || <FormattedMessage id="global.prepaid" defaultMessage="Pre-paid Shipping" />}
              </Typography>
              <Typography variant="h3" component="span">
                <FinancialValue value={-collect.prepaid_amount} currency={collect.display_currency} />
              </Typography>
            </div>
          )}

          <div className={classes.total}>
            <Typography variant="h3" component="span">
              <FormattedMessage
                id="global.total"
                defaultMessage="Total Paid ({currencyCode})"
                values={{ currencyCode: collect.display_currency }}
              />
            </Typography>
            <Typography variant="h1" component="span">
              <FinancialValue
                value={shipment_collect?.total_charge_after_prepayment as number}
                currency={collect.display_currency}
              />
            </Typography>
          </div>

          <ClientDisclaimer text={collect.page_disclaimer} url={collect.page_campaign_url} />
        </Paper>
      </Grid>
      <Grid item>
        <Footer company={shipment.company} terms="simple" />
      </Grid>
    </Grid>
  );
}

import { Address } from '@/services/backer/types';
import { Paper, Typography, Grid, CircularProgress, Box } from '@material-ui/core';
import Phone from '@material-ui/icons/Phone';

import { useStyles } from './styles';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';

interface AddressConfirmationProps {
  address: Address;
  onChangeClick: () => void;
  onConfirmClick: () => Promise<void>;
}

export default function AddressConfirmation({ address, onChangeClick, onConfirmClick }: AddressConfirmationProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  function handleConfirmClick() {
    setLoading(true);
    onConfirmClick().finally(() => setLoading(false));
  }

  return (
    <>
      <Typography align="center" className={classes.title} variant="h2">
        Is this shipping address correct?
      </Typography>
      <Paper className={classes.addressContainer} elevation={3}>
        <Box display="flex" alignItems="center">
          <Typography>{address.name}</Typography>
          <Phone className={classes.phoneIcon} />
          <Typography>{address.phone_number}</Typography>
        </Box>
        <Typography>
          {[address.address_line_1, address.address_line_2, address.city, address.state].filter(Boolean).join(', ')}
        </Typography>
        <Typography>
          {address.postal_code} {address.country.simplified_name}
        </Typography>
      </Paper>

      <Grid container justifyContent="space-between" className={classes.buttonContainer}>
        <Grid container item xs={12} sm={6} justifyContent="flex-start">
          <Button className={classes.textButton} variant="text" onClick={onChangeClick}>
            <FormattedMessage id="address.change" defaultMessage="No, change my address" />
          </Button>
        </Grid>
        <Grid container item xs={12} sm={6} justifyContent="flex-end">
          <Button className={classes.button} onClick={handleConfirmClick} disabled={loading}>
            <>
              {loading && <CircularProgress size={24} />}
              {!loading && <FormattedMessage id="address.approve" defaultMessage="Yes, correct" />}
            </>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

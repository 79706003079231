import { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button as MuiButton, ButtonProps } from '@material-ui/core';
import COLORS from '@/config/colors';
import ProgressIndicator from '../ProgressIndicator';

interface EsButtonProps {
  pending?: boolean;
  children?: string | ReactElement | ReactElement[];
}

const useStyles = makeStyles(() => ({
  transparent: {
    opacity: 0,
  },
  pending: {
    pointerEvents: 'none',
    backgroundImage: 'none !important',
    backgroundColor: COLORS.greenDark,
    boxShadow: '0 4px 4px 0 rgba(40, 78, 102, 0.20)',
    '& span.MuiButton-label > div': { backgroundColor: COLORS.greenDarkest },

    '&.MuiButton-text': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}));

export default function Button(props: EsButtonProps & ButtonProps) {
  const classes = useStyles();
  const { pending, children, ...buttonProps } = props;

  return (
    <MuiButton
      {...buttonProps}
      color={buttonProps.color || (buttonProps.variant === 'text' ? undefined : 'primary')}
      className={`${pending ? classes.pending : ''} ${buttonProps.className || ''}`}
      onClick={pending ? () => {} : buttonProps.onClick}
    >
      {pending && <ProgressIndicator />}
      <span className={pending ? classes.transparent : ''}>{children}</span>
    </MuiButton>
  );
}

import { useCallback, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import MainScreen from './screens/MainScreen';
import HomeScreen from './screens/HomeScreen';
import { theme } from './config/theme';
import { AvailableLocale, getLocale, messageDictionary } from './config/locale';
import { TrackingProvider } from './providers/TrackingProvider';

export interface RouteParams {
  paramOne?: string;
  paramTwo?: string;
}

function App() {
  const locale: AvailableLocale = getLocale();
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorMessage('');
  };

  const showErrorMessage = useCallback((error: Error) => setErrorMessage(error.message), []);

  return (
    <TrackingProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <IntlProvider locale={locale} messages={messageDictionary[locale] as Record<string, string>}>
          <BrowserRouter>
            <Switch>
              <Route path="/collect/:paramOne/:paramTwo?">
                <MainScreen showErrorMessage={showErrorMessage} />
              </Route>
              <Route path="/collect/:paramOne?">
                <MainScreen showErrorMessage={showErrorMessage} />
              </Route>
              <Route path="/">
                <HomeScreen />
              </Route>
            </Switch>
          </BrowserRouter>
          <Snackbar open={!!errorMessage} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} elevation={6} variant="filled" severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>
        </IntlProvider>
      </ThemeProvider>
    </TrackingProvider>
  );
}

export default App;

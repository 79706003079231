import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import { Item, Shipment } from '@/services/backer/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'left',

    '& h4': {
      padding: '14px 24px',

      [theme.breakpoints.down('xs')]: {
        padding: '10px 18px',
      },
    },
  },
  phoneIcon: {
    height: '0.65em',
    margin: '0 -3px -4px 3px',
    color: theme.palette.ink?.light,
  },
  bottomBorder: {
    padding: '10px 22px',
    borderBottom: 'solid 1px #F4F6F8',

    [theme.breakpoints.down('xs')]: {
      padding: '6px 15px',
    },
  },
  defList: {
    display: 'flex',
    flexFlow: 'row wrap',
    margin: 0,

    '& dt': {
      flexBasis: '65px',
      padding: '2px 4px',
    },

    '& dd': {
      flexBasis: 'calc(100% - 65px)',
      flexGrow: 1,
      margin: 0,
      padding: 2,

      '& p': {
        margin: 0,
      },
    },
  },
}));

export type ShipmentSummaryProps = {
  shipment: Shipment;
};

export default function ShipmentSummary(props: ShipmentSummaryProps) {
  const classes = useStyles();
  const { shipment } = props;
  const { address_line_1, address_line_2, city, state, postal_code, country } = shipment.destination_address;
  const concatenatedAddress = [address_line_1, address_line_2, city, state, postal_code, country.simplified_name]
    .filter((item) => !!item)
    .join(', ');

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.bottomBorder}>
        <FormattedMessage id="shipment.heading" defaultMessage="Order Details"></FormattedMessage>
      </Typography>
      <Grid container className={classes.bottomBorder}>
        <Grid item xs={12} sm={6}>
          <dl className={classes.defList}>
            {shipment.order_number && (
              <Fragment>
                <Typography variant="body2" component="dt">
                  <FormattedMessage id="shipment.order-number" defaultMessage="Order No.:"></FormattedMessage>
                </Typography>
                <Typography variant="body1" component="dd">
                  #{shipment.order_number.replace(/^#/, '')}
                </Typography>
              </Fragment>
            )}
            <Typography variant="body2" component="dt">
              <FormattedMessage
                id="shipment.items"
                defaultMessage="{count, plural, one{Item} other{Items}}:"
                values={{ count: shipment.shipment_items_attributes.reduce((agg, cur) => agg + cur.quantity, 0) }}
              ></FormattedMessage>
            </Typography>
            <Typography variant="body1" component="dd">
              {shipment.shipment_items_attributes.map((item: Item, index: number) => (
                <p key={index}>
                  {item.description} x {item.quantity}
                </p>
              ))}
            </Typography>
          </dl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <dl className={classes.defList}>
            <Typography variant="body2" component="dt">
              <FormattedMessage id="shipment.destination-address" defaultMessage="Ship To:"></FormattedMessage>
            </Typography>
            <Typography variant="body1" component="dd">
              <p>
                {shipment.destination_address.name}
                <PhoneIcon className={classes.phoneIcon} />
                {shipment.destination_address.phone_number}
              </p>
              <p>{concatenatedAddress}</p>
            </Typography>
          </dl>
        </Grid>
      </Grid>
    </div>
  );
}

import handleError from 'services/helpers/handleError';

import { CityAndState, AddressConfirmation, FormAddress, FormCountry } from './types';
import { Country } from '../backer//types';

const API_PATH = `${import.meta.env.VITE_API_BASE}/v1`;
const COLLECT_API_PATH = `${import.meta.env.VITE_COLLECT_API_BASE}/v1/backer/address`;
const HEADERS = { 'Content-Type': 'application/json' };

async function getCityAndState(postalCode: string): Promise<CityAndState> {
  const options = {
    method: 'GET',
    headers: HEADERS,
  };
  const res = await fetch(`${API_PATH}/countries/234/postal_codes/${postalCode}`, options);
  return res.ok ? res.json() : handleError(res);
}

async function getCountries(): Promise<FormCountry[]> {
  const options = {
    method: 'GET',
    headers: HEADERS,
  };
  const res = await fetch(`${API_PATH}/countries`, options);

  if (res.ok) {
    return res.json().then((data) =>
      data.countries.map((country: Country) => ({
        alpha2: country.alpha2,
        name: country.simplified_name,
        taxIdRequired: country.requirements.consignee_tax_id_required,
        postalCodeRegex: country.requirements.postal_code_regex || '',
      })),
    );
  }
  return handleError(res);
}

async function confirmAddress(token: string): Promise<AddressConfirmation> {
  const options = {
    method: 'POST',
    headers: { ...HEADERS, 'jwe-token': token },
  };
  const res = await fetch(`${COLLECT_API_PATH}/confirm`, options);
  return res.json();
}

async function updateAddress(token: string, address: FormAddress): Promise<AddressConfirmation> {
  const options = {
    method: 'PATCH',
    headers: { ...HEADERS, 'jwe-token': token },
    body: JSON.stringify(address),
  };
  const res = await fetch(COLLECT_API_PATH, options);
  return res.json();
}

export default { getCityAndState, confirmAddress, getCountries, updateAddress };

import { useState, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Company } from '@/services/backer/types';
import ContactInfoDialog from '@/components/ContactInfoDialog';
import { useTracking } from '@/providers/TrackingProvider';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    maxWidth: 600,
    margin: 16,
    textAlign: 'center',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
}));

export interface FooterProps {
  company: Company;
  terms?: 'full' | 'simple';
  postPayment?: boolean;
  campaignUrl?: string | null;
}

export default function Footer({ company, terms, postPayment, campaignUrl }: FooterProps) {
  const classes = useStyles();
  const { emitEvent } = useTracking();
  const [open, setOpen] = useState(false);

  const hasContactInfo =
    company.cs_url ||
    company.contact_email ||
    company.phone_number ||
    company.facebook_url ||
    company.twitter_url ||
    company.instagram_url ||
    company.youtube_url;

  const dialogTrigger = (chunks: string) =>
    hasContactInfo ? (
      <span onClick={() => setOpen(true)} className={classes.link} data-testid="dialog-trigger">
        {chunks}
      </span>
    ) : (
      <span>{chunks}</span>
    );

  const termsLink = (chunks: string) => (
    <a
      href="https://www.easyship.com/legal/collect-service"
      target="_blank"
      rel="noopener noreferrer"
      className={classes.link}
      onClick={() => emitEvent('Collect form - T&C')}
    >
      {chunks}
    </a>
  );

  const companyLink = (chunks: string) => (
    <a href={campaignUrl || '#'} target="_blank" rel="noopener noreferrer" className={classes.link}>
      {chunks}
    </a>
  );

  return (
    <Fragment>
      {campaignUrl && (
        <Typography variant="body2" className={classes.text}>
          <FormattedMessage
            id="footer.contact"
            defaultMessage="For more info, please <span>visit our page.</span>"
            values={{ span: companyLink }}
          ></FormattedMessage>
        </Typography>
      )}
      {hasContactInfo && (
        <Typography variant="body2" className={classes.text}>
          <FormattedMessage
            id="footer.contact"
            defaultMessage="Easyship is the nominated shipping provider working on behalf of {companyName}. For any questions, please contact <dialogTrigger>{companyName}</dialogTrigger> directly."
            values={{ companyName: company.name, dialogTrigger }}
          ></FormattedMessage>
        </Typography>
      )}
      {terms === 'full' && (
        <Typography variant="body2" className={classes.text}>
          <FormattedMessage
            id="footer.proceed-payment"
            defaultMessage="By clicking {buttonText} you agree to <a>Easyship’s Terms and Conditions</a>."
            values={{
              companyName: company.name,
              buttonText: postPayment ? '"Pay Now"' : '"Confirm"',
              a: termsLink,
            }}
          ></FormattedMessage>
        </Typography>
      )}
      {terms === 'simple' && (
        <Typography variant="body2" className={classes.text}>
          <FormattedMessage
            id="footer.terms-only"
            defaultMessage="<a>Easyship’s Terms and Conditions</a>"
            values={{ a: termsLink }}
          ></FormattedMessage>
        </Typography>
      )}

      <ContactInfoDialog company={company} open={open} onClose={() => setOpen(false)} />
    </Fragment>
  );
}

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '30px 10px',
    overflowX: 'hidden',
    maxWidth: 600,
    margin: '0 auto',
    '& .MuiOutlinedInput-root': {
      marginTop: 4,
    },
  },
  addressContainer: {
    padding: '20px 24px',
    marginBottom: 32,
    marginTop: 20,
  },
  title: {
    marginBottom: 20,
    fontSize: 18,
  },
  phoneIcon: {
    color: theme.palette.ink?.light,
    width: 12,
    height: 12,
    margin: '0 6px',
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  button: {
    minWidth: 200,
    '& .MuiButton-label > span': {
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  textButton: {
    '&.MuiButton-text': {
      padding: '10px 12px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  grid: {
    '& .MuiGrid-item': {
      padding: 6,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 6,
    marginTop: 20,
    marginBottom: 16,
  },
}));

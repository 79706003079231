import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Paper, Typography } from '@material-ui/core';

import Title from '@/components/Title';
import { useTracking } from '@/providers/TrackingProvider';

import { useStyles } from './styles';

interface NotFoundScreenProps {
  message?: string;
}

const DEFAULT_MESSAGE =
  'It seems that this Collect campaign is currently inactive. Please contact the merchant for more information';

export default function NotFoundScreen({ message = DEFAULT_MESSAGE }: NotFoundScreenProps) {
  const classes = useStyles();
  const intl = useIntl();
  const { emitEvent } = useTracking();

  emitEvent('Arrive - Not Found');

  return (
    <Grid container direction="column" alignItems="center" spacing={2} className={classes.root}>
      <Grid item>
        <Title />
      </Grid>
      <Grid item>
        <Paper elevation={3} className={classes.paper}>
          <img
            src="/images/icon-lost.svg"
            alt={intl.formatMessage({ id: 'not-found.icon', defaultMessage: 'shipment not found icon' })}
            className={classes.icon}
          />
          <Typography variant="body1" className={classes.text}>
            <FormattedMessage id="not-found.text" defaultMessage={message}></FormattedMessage>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

import { PaymentIntent } from '@stripe/stripe-js';

export interface IBackerService {
  get: (token: string) => Promise<Shipment>;
  setPrepayTax: (token: string, prepayTax: boolean) => Promise<Shipment>;
  selectCourier: (token: string, courierId: string) => Promise<Shipment>;
  setInsurance: (token: string, isInsured: boolean) => Promise<Shipment>;
  preparePayment: (token: string, totalChargeAfterPrepayment: number) => Promise<StripeTokens>;
  reportPayment: (token: string) => Promise<Shipment>;
}

export interface IBackerResource {
  step: (token: string) => Promise<Shipment>;
  recalculate: (token: string, payload: CollectionPayload) => Promise<Shipment>;
  paymentIntent: (token: string, payload: PaymentIntentPayload) => Promise<StripeTokens>;
  confirm: (token: string) => Promise<Shipment>;
}

export type Shipment = {
  id: string;
  order_number: string | null;
  collect: CollectionStatus;
  company: Company;
  shipment_items_attributes: Item[];
  destination_address: Address;
  effective_incoterms?: Incoterms;
  is_insured?: boolean;
  courier_id?: string;
  payment_intent?: PaymentIntentSummary;
  rates?: CourierService[];
  shipment_collect?: CourierService;
  ddp_total_import_tax_and_duty: number;
  ddu_total_import_tax_and_duty: number;
  beta_feature_address: boolean;
  address_confirmed: boolean;
  address_valid?: boolean;
  origin_address: {
    country: {
      simplified_name: string;
      alpha2: string;
    };
  };
  user_role: string;
};

export type CollectionStatus = {
  current_step: CollectionStep;
  accessible_steps: CollectionStep[];
  show_incoterms_option: boolean | null;
  show_insurance_option: boolean | null;
  show_address_option: boolean | null;
  subsidy_wording: string | null;
  sales_tax_names: SalesTaxTerms;
  prepaid_wording: string | null;
  prepaid_amount: number | null;
  display_currency: Currency | null;
  page_disclaimer?: string | null;
  page_campaign_url?: string | null;
  merchant_fee_title?: string | null;
  merchant_fee_description?: string | null;
  merchant_fee_amount?: number | null;
  merchant_fee_currency?: Currency | null;
  shipping_options_display: string | null;
  collected_amount?: number;
  paymentReported?: boolean;
};

export interface CollectionPayload {
  incoterms?: Incoterms;
  is_insured?: boolean;
  courier_id?: string;
}

export interface PaymentIntentPayload {
  id?: string; // shipment collect id
  total_charge_after_prepayment: number; // in USD
}

export type Company = {
  name: string | null;
  logo_url: string | null;
  contact_email: string | null;
  phone_number: string | null;
  cs_url: string | null;
  facebook_url: string | null;
  twitter_url: string | null;
  instagram_url: string | null;
  youtube_url: string | null;
};

export type Country = {
  simplified_name: string;
  id?: number;
  name?: string;
  alpha2?: string;
  requirements: {
    consignee_tax_id_required: boolean;
    postal_code_regex: string;
  };
};

export type Address = {
  name: string;
  company_name?: string;
  phone_number?: string;
  email_address?: string;
  address_line_1: string;
  address_line_2?: string;
  address_line_3?: string;
  city: string;
  state?: string;
  postal_code?: string;
  country: Country;
  consignee_tax_id: string;
};

export type PaymentIntentSummary = {
  id: string; // pi_xxx
  client_secret: string; // pi_xxx_secret_xxx
  // status: PaymentIntentStatus; // https://stripe.com/docs/payments/intents#intent-statuses
  status: PaymentIntent.Status; // https://stripe.com/docs/payments/intents#intent-statuses
  amount: number; // in cents
  currency: string; // lowercase, e.g. 'usd'
};

export type CourierService = {
  courier_id: string;
  courier_display_name: string;
  courier_logo: string;
  min_delivery_time: number;
  max_delivery_time: number;
  subsidy_applied: boolean;
  base_charge_after_subsidy: number; // shipping cost + surcharges + sales taxes - subsidy
  import_tax_charge?: number;
  import_duty_charge?: number;
  ddp_handling_fee?: number;
  estimated_import_tax: number;
  estimated_import_duty: number;
  insurance_fee: number;
  insurance_fee_not_applied: number;
  total_charge_after_prepayment: number;
  cost_rank?: number;
  delivery_time_rank?: number;
  value_for_money_rank?: number;
};

export type Item = {
  description: string;
  quantity: number;
};

export type StripeTokens = {
  stripe_pk: string;
  id: string;
  client_secret: string;
  amount: number;
  currency: string;
};

export type SalesTaxTerms = {
  sales_tax_name: string | null;
  provincial_sales_tax_name: string | null;
  sales_tax_number_name?: string | null;
};

export enum CollectionStep {
  Address = 'address',
  Loading = 'loading',
  Incoterms = 'incoterms',
  Shipping = 'shipping',
  Success = 'success',
  Inactive = 'inactive',
  NotFound = 'notFound', // not used by API
}

export enum Incoterms {
  DDP = 'DDP', // Taxes and duties will be prepaid to Easyship
  DDU = 'DDU', // Taxes and duties will be unpaid until delivery (recipient may or may not need to pay then)
}

export type IncotermsValues = {
  ddpAmount: number;
  dduRangeMin: number;
  dduRangeMax: number;
  maxAboveDdp: number;
};

export enum Currency {
  USD = 'USD',
}

export enum CourierLogoAbbr {
  'insurance',
  'airpak',
  'alliedexpress',
  'apc',
  'aramex',
  'asendia',
  'au-post',
  'australia-post',
  'australia-post-on-demand',
  'bpost-international',
  'canada-post',
  'canpar',
  'choicebro',
  'chronopost-france',
  'coe',
  'colissimo',
  'couriers-please',
  'couriersplease',
  'dhl-ecommerce',
  'dhl-global-mail',
  'dhl',
  'directlink',
  'dpd',
  'dpex',
  'dtdc',
  'easyrate',
  'easyship',
  'ebay_envelope',
  'ems',
  'fastway',
  // 'fedex-crossborder',
  'fedex',
  'flatexportrate',
  'freight-economy',
  'globalpost',
  'hermes',
  'hong-kong-post',
  'janio',
  'jtexpress',
  'lalamove',
  'lasership',
  'leading_courier_f',
  'leading_courier_u',
  'lhtexpress',
  'loomis',
  'lwe-hk',
  'mondialrelay',
  'ninjavan',
  'nonbranded',
  'nzpost',
  'omnichannel',
  'omniparcel',
  'on-trac',
  'ontrac',
  'osm',
  'p2p',
  'parcelexpress',
  'parcelforce',
  'parcelpoint',
  'pickupp',
  'postnl',
  'purolator',
  'quantium',
  'qxpress',
  'roadbull',
  'royal-mail',
  'rrd',
  'seko',
  'sendle',
  'sf-express',
  'simplypost',
  'singaporepost',
  'skypostal',
  'star-track',
  'taqbin-hk',
  'tnt',
  'toll',
  'ups',
  'ups-ready',
  'usps',
  'uspsdhl',
  'wndirect',
  'yunexpress',
}

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '30px 0',
    overflowX: 'hidden',
  },
  paper: {
    maxWidth: 600,
    margin: '0 10px',
    paddingBottom: 55,
    textAlign: 'center',
  },
  logo: {
    maxWidth: 420,
    marginTop: 30,
  },
  heading: {
    marginTop: 15,
    marginBottom: 10,
  },
  text: {
    maxWidth: 400,
    width: 'calc(100% - 50px)',
    margin: 'auto',
  },
  button: {
    marginTop: 20,
    padding: '8px 60px',
  },
}));

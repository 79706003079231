import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '30px 10px',
    overflowX: 'hidden',
    maxWidth: 600,
    margin: '0 auto',
    textAlign: 'center',
  },

  pageTitle: {
    marginBottom: 32,
    '& h2': {
      fontSize: 28,
      lineHeight: 1.5,
      marginBottom: 20,
      marginTop: 8,
    }
  },

  grid: {
    textAlign: 'start',
    marginBottom: 32,
    '& .MuiDivider-root': {
      backgroundColor: theme.palette.sky?.main,
    }
  },

  gridItem: {
    padding: '8px 24px',
  }
}));

import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';

import Title from '@/components/Title';

import { useStyles } from './styles';
import Footer from '@/components/Footer';
import { Shipment } from '@/services/backer/types';
import Button from '@/components/Button';

interface ErrorScreenProps {
  shipment: Shipment;
  goBack?: () => void;
}

export default function ErrorScreen({ shipment, goBack }: ErrorScreenProps) {
  const classes = useStyles();
  const intl = useIntl();

  const isBackButtonVisible = !!goBack;

  return (
    <Grid container direction="column" alignItems="center" spacing={2} className={classes.root}>
      <Grid item>
        <Title role={shipment.user_role} />
      </Grid>
      <Grid item>
        <img
          src="/images/no-shipping-options.svg"
          alt={intl.formatMessage({ id: 'no-shipping.image', defaultMessage: 'no shipping options image' })}
          className={classes.icon}
        />
        <Typography variant="h1" className={classes.title}>
          <FormattedMessage id="error.title" defaultMessage="No shipping options available"></FormattedMessage>
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="error.text"
            defaultMessage="Seems like no shipping options are available for the address you provided. Please ensure that your address has been entered correctly."
          ></FormattedMessage>
        </Typography>
        {isBackButtonVisible && (
          <Button onClick={goBack} fullWidth className={classes.button}>
            <FormattedMessage id="no-shipping.button" defaultMessage="Edit address" />
          </Button>
        )}
      </Grid>
      <Grid item>
        <Footer company={shipment.company} campaignUrl={shipment.collect.page_campaign_url} />
      </Grid>
    </Grid>
  );
}

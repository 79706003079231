import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  FormControl,
  RadioGroup,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';

import { CourierService, Currency, SalesTaxTerms } from '@/services/backer/types';
import WideRow from './WideRow';
import NarrowRow from './NarrowRow';
import { useTracking } from '@/providers/TrackingProvider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  table: {
    '& .MuiTableCell-root': {
      borderBottom: `1px solid ${theme.palette.sky?.main}`,

      '&:first-child': {
        paddingLeft: 24,

        [theme.breakpoints.down('xs')]: {
          paddingLeft: 12,
        },
      },
      '&:last-child': {
        paddingRight: 24,
        whiteSpace: 'nowrap',
      },
    },

    '& .MuiFormControlLabel-root': { marginRight: 0 },
  },
  alignTop: {
    '& .MuiTableCell-head': {
      verticalAlign: 'text-top',
      paddingTop: 12,
      paddingBottom: 12,

      '& .MuiTypography-root': { lineHeight: '1em' },
    },
  },
  radioLabel: { flexWrap: 'nowrap' },
  chip: { backgroundColor: theme.palette.teal?.main },
  icon: {
    width: 40,
    marginTop: 4,
  },
  details: {
    '& p': { margin: 0 },
  },
  selected: { color: theme.palette.ink?.dark },
  unselected: {
    '& span': {
      color: theme.palette.ink?.light,
    },
  },
}));

export interface ServiceSelectorProps {
  currency: Currency | null;
  rates: CourierService[];
  suggestedId: string;
  subsidy_wording: string;
  sales_tax_names: SalesTaxTerms;
  onSelect: (courierId: string) => void | Promise<void>;
}

export default function ServiceSelector(props: ServiceSelectorProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const { emitEvent } = useTracking();

  const { rates, currency, suggestedId, subsidy_wording, sales_tax_names, onSelect } = props;
  const { sales_tax_name, provincial_sales_tax_name } = sales_tax_names;
  const selectedId = suggestedId || (rates?.length && rates[0].courier_id);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const courierId = event.target.value;

    if (onSelect) onSelect(courierId);

    emitEvent('Collect form - Shipping - Change courier');
  }

  if (!rates) return null;

  const cheapestIsFree = rates.some(({ base_charge_after_subsidy }) => base_charge_after_subsidy === 0);
  const salesTaxNames =
    sales_tax_name || provincial_sales_tax_name
      ? `incl. ${[sales_tax_name, provincial_sales_tax_name].filter((s) => !!s).join(', ')}`
      : '';

  return (
    <FormControl component="fieldset" className={classes.root}>
      <RadioGroup aria-label="courier service" value={selectedId} onChange={handleChange}>
        <Table aria-label="rates table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.alignTop}>
              <TableCell>
                <Typography variant="subtitle1">
                  <FormattedMessage id="courier-service.options" defaultMessage="Shipping Options"></FormattedMessage>
                </Typography>
              </TableCell>

              {!isNarrow && (
                <TableCell align="center">
                  <Fragment>
                    <Typography variant="subtitle1">
                      <FormattedMessage
                        id="courier-service.delivery"
                        defaultMessage="Estimated Delivery Time"
                      ></FormattedMessage>
                    </Typography>
                    <Typography gutterBottom variant="caption">
                      <FormattedMessage
                        id="courier-service.from-ship-date"
                        defaultMessage="(from ship date)"
                      ></FormattedMessage>
                    </Typography>
                  </Fragment>
                </TableCell>
              )}

              <TableCell align="right">
                {!isNarrow && (
                  <Typography variant="subtitle1">
                    <FormattedMessage id="courier-service.cost" defaultMessage="Amount"></FormattedMessage>
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rates.map((option) =>
              isNarrow ? (
                <NarrowRow
                  key={option.courier_id}
                  courierService={option}
                  currency={currency}
                  selected={selectedId === option.courier_id}
                  subsidyWording={subsidy_wording}
                  salesTaxNames={salesTaxNames}
                  cheapestIsFree={cheapestIsFree}
                  disableRankChips={rates.length === 1}
                />
              ) : (
                <WideRow
                  key={option.courier_id}
                  courierService={option}
                  currency={currency}
                  selected={selectedId === option.courier_id}
                  subsidyWording={subsidy_wording}
                  salesTaxNames={salesTaxNames}
                  cheapestIsFree={cheapestIsFree}
                  disableRankChips={rates.length === 1}
                />
              ),
            )}
          </TableBody>
        </Table>
      </RadioGroup>
    </FormControl>
  );
}

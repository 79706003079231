import { Typography, TextField, InputLabel, TextFieldProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface FormInputProps {
  label: string;
  optional?: boolean;
  maxLength?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      marginTop: 4,
    },
  },
  optional: {
    color: theme.palette.ink?.light,
    marginLeft: 8,
  },
}));

export default function FormInput({
  label,
  optional,
  maxLength = 100,
  ...textFieldProps
}: FormInputProps & TextFieldProps) {
  const classes = useStyles();

  return (
    <InputLabel className={classes.root}>
      <Typography variant="body2">
        {label}
        {optional && <span className={classes.optional}>(optional)</span>}
      </Typography>
      <TextField {...textFieldProps} variant="outlined" fullWidth required={!optional} inputProps={{ maxLength }} />
    </InputLabel>
  );
}

import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Chip, Grid, Typography } from '@material-ui/core';

import FinancialValue from '@/components/FinancialValue/FinancialValue';
import { CourierService, Currency, SalesTaxTerms } from '@/services/backer/types';

const IMPORTANT_ZERO = '0 !important';

const useStyles = makeStyles((theme: Theme) => ({
  narrowContainer: {
    paddingRight: 16,

    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 120px)',
    },
  },
  fullWidth: { width: '100%' },
  upperLeft: { width: 'calc(100% - 90px)' },
  upperRight: {
    width: 90,
    textAlign: 'right',
    '& h3': { fontWeight: 'bold' },
  },
  heading: { margin: 0 },
  chip: {
    backgroundColor: theme.palette.teal?.main,
  },
  icon: {
    width: 40,
    marginLeft: 5,
    padding: IMPORTANT_ZERO,

    [theme.breakpoints.down('xs')]: {
      width: 50,
    },

    '& img': {
      width: '100%',
      marginTop: 4,
      borderRadius: 4,
    },
  },
  details: {
    maxWidth: 'calc(100% - 45px)',
    paddingRight: IMPORTANT_ZERO,

    '& > div > p': {
      margin: 0,
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  price: {
    paddingLeft: 20,
    whiteSpace: 'nowrap',
  },
  right: {
    maxWidth: 120,
    textAlign: 'right',
    '& h3': { fontWeight: 'bold' },
  },
  baseCharge: {
    marginTop: 40,
    '& h3': { fontWeight: 'bold' },
  },
}));

export type ServiceSummaryProps = React.HTMLAttributes<string> & {
  courierService: CourierService;
  currency: Currency | null;
  subsidy_wording: string;
  sales_tax_names: SalesTaxTerms;
  optionsDisplayDisabled?: boolean;
};

export default function ServiceSummary(props: ServiceSummaryProps) {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    courier_logo,
    courier_display_name,
    min_delivery_time,
    max_delivery_time,
    base_charge_after_subsidy,
    cost_rank,
    delivery_time_rank,
    value_for_money_rank,
  } = props.courierService;
  const isTopRankedInAll = cost_rank === 1 && delivery_time_rank === 1 && value_for_money_rank === 1;
  const { sales_tax_name, provincial_sales_tax_name } = props.sales_tax_names;
  const salesTaxNames =
    sales_tax_name || provincial_sales_tax_name
      ? `incl. ${[sales_tax_name, provincial_sales_tax_name].filter((s) => !!s).join(', ')}`
      : '';

  return (
    <Grid container justifyContent="space-between" alignItems="center" className={props.className || ''}>
      <Grid
        item
        container
        spacing={1}
        direction="column"
        justifyContent="space-between"
        className={classes.narrowContainer}
      >
        {!props.optionsDisplayDisabled ? (
          <Grid item container spacing={2} justifyContent="space-between">
            <Grid item className={isNarrow ? classes.upperLeft : classes.fullWidth}>
              <Typography variant="subtitle1" className={classes.heading}>
                <FormattedMessage id="courier-service.selected" defaultMessage="Shipping selected:"></FormattedMessage>
              </Typography>
            </Grid>
            {isNarrow && props.optionsDisplayDisabled && (
              <Grid item className={classes.upperRight}>
                {base_charge_after_subsidy ? (
                  <Typography variant="h3">
                    <FinancialValue value={base_charge_after_subsidy} currency={props.currency} />
                  </Typography>
                ) : (
                  <Typography variant="h4">
                    {props.subsidy_wording || (
                      <FormattedMessage id="courier-service.paid" defaultMessage="Paid"></FormattedMessage>
                    )}
                  </Typography>
                )}
                {salesTaxNames && <Typography variant="caption">{salesTaxNames}</Typography>}
              </Grid>
            )}
          </Grid>
        ) : (
          <Typography variant="subtitle1" className={classes.heading}>
            <FormattedMessage id="courier-service.selected" defaultMessage="Shipping selected:"></FormattedMessage>
          </Typography>
        )}
        <Grid item container spacing={2} wrap="nowrap" alignItems="center">
          <Grid item className={classes.icon}>
            <img
              alt={courier_display_name}
              src={`https://assets.easyship.com/app/courier-logos/${courier_logo}-mini.svg`}
            />
          </Grid>
          <Grid
            item
            container
            spacing={isNarrow && !props.optionsDisplayDisabled ? 1 : 0}
            direction={isNarrow && !props.optionsDisplayDisabled ? 'row' : 'column'}
            justifyContent="space-between"
            wrap="nowrap"
            className={classes.details}
          >
            <Grid item>
              {!props.optionsDisplayDisabled && !isTopRankedInAll && (
                <div>
                  {cost_rank === 1 && (
                    <Chip
                      label={intl.formatMessage({ id: 'courier-service.cheapest', defaultMessage: 'Cheapest' })}
                      color="primary"
                      className={classes.chip}
                    />
                  )}
                  {delivery_time_rank === 1 && (
                    <Chip
                      label={intl.formatMessage({ id: 'courier-service.fastest', defaultMessage: 'Fastest' })}
                      color="primary"
                      className={classes.chip}
                    />
                  )}
                  {value_for_money_rank === 1 && (
                    <Chip
                      label={intl.formatMessage({ id: 'courier-service.best-value', defaultMessage: 'Best Value' })}
                      color="primary"
                      className={classes.chip}
                    />
                  )}
                </div>
              )}
              <Typography variant="body1">{courier_display_name}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {isNarrow && (
                  <Fragment>
                    {!props.optionsDisplayDisabled && (
                      <div className={classes.right}>
                        <Typography variant="h3">
                          <FinancialValue value={base_charge_after_subsidy} currency={props.currency} />
                        </Typography>
                      </div>
                    )}
                    <FormattedMessage
                      id="courier-service.delivery-range-abbr"
                      defaultMessage="{min_delivery_time} - {max_delivery_time} working days"
                      values={
                        min_delivery_time + max_delivery_time
                          ? { min_delivery_time, max_delivery_time }
                          : { min_delivery_time: 29, max_delivery_time: 36 }
                      }
                    ></FormattedMessage>
                  </Fragment>
                )}
              </Typography>
            </Grid>
          </Grid>
          {isNarrow && props.optionsDisplayDisabled && (
            <Grid item className={classes.right}>
              {base_charge_after_subsidy ? (
                <Typography variant="h3">
                  <FinancialValue value={base_charge_after_subsidy} currency={props.currency} />
                </Typography>
              ) : (
                <Typography variant="h4">
                  {props.subsidy_wording || (
                    <FormattedMessage id="courier-service.paid" defaultMessage="Paid"></FormattedMessage>
                  )}
                </Typography>
              )}
              {salesTaxNames && <Typography variant="caption">{salesTaxNames}</Typography>}
            </Grid>
          )}
        </Grid>
      </Grid>
      {!isNarrow && (
        <Fragment>
          <Grid item className={classes.baseCharge}>
            {base_charge_after_subsidy ? (
              <Typography variant="h3">
                <FinancialValue value={base_charge_after_subsidy} currency={props.currency} />
              </Typography>
            ) : (
              <Typography variant="h4">
                {props.subsidy_wording || (
                  <FormattedMessage id="courier-service.paid" defaultMessage="Paid"></FormattedMessage>
                )}
              </Typography>
            )}
            {salesTaxNames && <Typography variant="caption">{salesTaxNames}</Typography>}
          </Grid>
          <Typography variant="body2">
            <FormattedMessage
              id="courier-service.delivery-range"
              defaultMessage="Estimated Delivery Time: {min_delivery_time} - {max_delivery_time} working days after shipment"
              values={
                min_delivery_time + max_delivery_time
                  ? { min_delivery_time, max_delivery_time }
                  : { min_delivery_time: 29, max_delivery_time: 36 }
              }
            ></FormattedMessage>
          </Typography>
        </Fragment>
      )}
    </Grid>
  );
}

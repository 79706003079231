import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';

import Switch from '@/components/Switch';
import { formatFinancialValue } from '@/components/FinancialValue/FinancialValue';
import { Currency } from '@/services/backer/types';
import { useTracking } from '@/providers/TrackingProvider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  heading: { marginBottom: 5 },
  icon: {
    height: 20,
    marginRight: 5,
  },
  details: {
    maxWidth: 'calc(100% - 260px)',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 137px)',
    },
  },
  info: {
    width: '0.6em',
    verticalAlign: 'middle',
    margin: '0 5px',
  },
  field: {
    maxWidth: 229,
    justifyContent: 'flex-end',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 106,
      paddingRight: 0,
    },
  },
  switch: {
    padding: '8px 20px !important',

    [theme.breakpoints.down('xs')]: {
      padding: '8px 0 !important',

      '& .MuiGrid-container': { justifyContent: 'flex-end' },
    },
  },
  price: {
    textAlign: 'right',
    minWidth: 60,

    '& h3': { fontWeight: 'bold' },
  },
}));

export type InsuranceSelectorProps = React.HTMLAttributes<string> & {
  is_insured: boolean;
  insurance_fee?: number;
  insurance_fee_not_applied?: number;
  currency: Currency | null;
  onToggle?: (value: boolean) => void | Promise<void>;
};

export default function InsuranceSelector(props: InsuranceSelectorProps) {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const { emitEvent } = useTracking();
  const { is_insured, insurance_fee = 0, insurance_fee_not_applied = 0, currency, onToggle } = props;
  const fee = formatFinancialValue(insurance_fee || insurance_fee_not_applied, currency);
  const [buyingInsurance, setBuyingInsurance] = useState(is_insured);
  const [showTooltip, setShowTooltip] = useState(false);

  function handleToggle(value: boolean) {
    setBuyingInsurance(value);
    if (onToggle) onToggle(value);

    emitEvent('Collect form - Shipping - Toggle insurance');
  }

  return (
    <div className={`${classes.root} ${props.className || ''}`}>
      <Grid container spacing={1} alignItems={isNarrow ? 'flex-start' : 'center'}>
        <Grid item>
          <img
            className={classes.icon}
            alt={intl.formatMessage({ id: 'insurance.logo', defaultMessage: 'insurance icon' })}
            src="/images/icon-shield.svg"
          />
        </Grid>
        <Grid item className={classes.details}>
          <Typography variant="subtitle1" className={classes.heading}>
            <FormattedMessage id="insurance.heading" defaultMessage="Shipping Insurance"></FormattedMessage>
          </Typography>
          <Typography variant="caption" gutterBottom>
            <FormattedMessage
              id="insurance.prompt"
              defaultMessage="Add {fee} to cover your order from unexpected damage, loss, or theft during shipping"
              values={{ fee }}
            ></FormattedMessage>

            <Tooltip
              interactive
              arrow
              placement={isNarrow ? 'top' : 'left'}
              onClose={() => setShowTooltip(false)}
              open={showTooltip}
              title={
                <FormattedMessage
                  id="insurance.tooltip"
                  defaultMessage="Easyship covers any loss or damage during shipping. Claims are simple to make and are processed within 5-7 days. <a>Learn more</a>"
                  values={{
                    a: (chunks: string) => (
                      <a
                        href="https://www.easyship.com/blog/the-basics-of-shipping-insurance"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {chunks}
                      </a>
                    ),
                  }}
                ></FormattedMessage>
              }
              leaveDelay={1500}
            >
              <InfoIcon
                onClick={() => setShowTooltip(true)}
                onMouseOver={() => setShowTooltip(true)}
                className={classes.info}
              />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction={isNarrow ? 'column-reverse' : 'row'}
          alignItems={isNarrow ? 'flex-end' : 'center'}
          justifyContent="space-between"
          wrap="nowrap"
          className={classes.field}
        >
          <Grid item className={classes.switch}>
            <Switch checked={buyingInsurance} onToggle={handleToggle} />
          </Grid>
          <Grid item className={classes.price}>
            <Typography variant="h3">{buyingInsurance ? fee : '-'}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

import Title from '@/components/Title';
import { Item, Shipment } from '@/services/backer/types';
import { useStyles } from './styles';
import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Button from '@/components/Button';
import Footer from '@/components/Footer';
import ProgressIndicator from '@/components/ProgressIndicator';

interface LandingScreenProps {
  shipment: Shipment;
  onClick: () => void;
}

export default function LandingScreen({ shipment, onClick }: LandingScreenProps) {
  const classes = useStyles();

  if (!shipment) return <ProgressIndicator centered size={3} />;

  return (
    <div className={classes.root}>
      <Title name={shipment.company.name} logoUrl={shipment.company.logo_url} role={shipment.user_role} />
      <div className={classes.pageTitle}>
        <Typography variant="h2">
          <FormattedMessage id="landing.title" defaultMessage="Confirm your shipping"></FormattedMessage>
        </Typography>
        <Typography variant="body2">
          <FormattedMessage
            id="landing.subtitle"
            defaultMessage="Once you have submitted this form, your order will be shipped out when it is ready!"
          ></FormattedMessage>
        </Typography>
      </div>
      <Paper elevation={3}>
        <Grid container direction="column" className={classes.grid}>
          {shipment.order_number && (
            <Grid container item className={classes.gridItem}>
              <Grid item xs={3} md={2}>
                <Typography variant="body2">
                  <FormattedMessage id="shipment.order-number" defaultMessage="Order No.:"></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item xs={9} md={10}>
                <Typography variant="body1">{shipment.order_number.replace(/^#/, '')}</Typography>
              </Grid>
            </Grid>
          )}
          <Divider />
          <Grid container item className={classes.gridItem}>
            <Grid item xs={3} md={2}>
              <Typography variant="body2">
                <FormattedMessage
                  id="shipment.items"
                  defaultMessage="{count, plural, one{Item} other{Items}}:"
                  values={{ count: shipment.shipment_items_attributes.reduce((agg, cur) => agg + cur.quantity, 0) }}
                ></FormattedMessage>
              </Typography>
            </Grid>
            <Grid item xs={9} md={10}>
              {shipment.shipment_items_attributes.map((item: Item, index: number) => (
                <Typography variant="body1" key={index}>
                  {item.description} x {item.quantity}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Button fullWidth onClick={onClick}>
        <FormattedMessage id="landing.button" defaultMessage="Let's start"></FormattedMessage>
      </Button>

      <Footer company={shipment.company} campaignUrl={shipment.collect.page_campaign_url} />
    </div>
  );
}

import { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Chip, Grid, Typography } from '@material-ui/core';

import { formatFinancialValue } from '@/components/FinancialValue/FinancialValue';
import { CourierService, Incoterms, Shipment } from '@/services/backer/types';

const useStyles = makeStyles(() => ({
  root: {},
  heading: { marginBottom: 5 },
  chip: { margin: '0 2px' },
  bold: { fontWeight: 'bold' },
  white: { color: 'white' },
  icon: {
    width: 18,
    margin: '4px 5px 0 0',
  },
  details: {
    '& p': { margin: 0 },
  },
  price: {
    paddingLeft: 20,
    '& h3': { fontWeight: 'bold' },
  },
}));

export type IncotermsSummaryProps = React.HTMLAttributes<string> & {
  shipment: Shipment;
};

export default function IncotermsSummary(props: IncotermsSummaryProps) {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));

  const { collect, effective_incoterms, shipment_collect, rates, courier_id } = props.shipment;
  const preselectedService =
    shipment_collect ||
    rates?.find((courierService) => courierService.courier_id === courier_id) ||
    ({} as CourierService);
  const {
    import_tax_charge = 0,
    import_duty_charge = 0,
    ddp_handling_fee = 0,
    estimated_import_tax = 0,
    estimated_import_duty = 0,
  } = preselectedService;

  if (estimated_import_tax + estimated_import_duty + import_duty_charge + import_tax_charge === 0) return null;

  const ddpTotal = formatFinancialValue(
    import_tax_charge + import_duty_charge + ddp_handling_fee,
    collect.display_currency,
  );
  const dduEstimate = estimated_import_tax + estimated_import_duty;
  const format = (value: number) => intl.formatNumber(value, { minimumFractionDigits: 2 });
  const dduRange = `${format(dduEstimate + 10)} - ${format(dduEstimate + 30)} ${collect.display_currency}`;

  if (!effective_incoterms || ![Incoterms.DDU, Incoterms.DDP].includes(effective_incoterms)) return null;

  const prepay = effective_incoterms === Incoterms.DDP;

  return (
    <div className={`${classes.root} ${props.className || ''}`}>
      <Grid container spacing={1} alignItems={isNarrow ? 'flex-start' : 'center'}>
        <Grid item>
          <img
            className={classes.icon}
            alt={intl.formatMessage({ id: 'incoterms.logo', defaultMessage: 'tax icon' })}
            src="/images/icon-tax.svg"
          />
        </Grid>
        <Grid item xs className={classes.details}>
          {!isNarrow ? (
            <Fragment>
              <Typography variant="subtitle1" className={classes.heading}>
                <FormattedMessage
                  id="incoterms.heading"
                  defaultMessage="Import Tax, Duty, and associated fees"
                ></FormattedMessage>
                {prepay ? (
                  <Chip
                    label={intl.formatMessage({ id: 'incoterms.ddp', defaultMessage: 'Pre-pay' })}
                    color="primary"
                    className={`${classes.chip} ${classes.bold}`}
                  />
                ) : (
                  <Chip
                    label={intl.formatMessage({ id: 'incoterms.ddu', defaultMessage: 'Pay on arrival' })}
                    className={`${classes.chip} ${classes.white}`}
                  />
                )}
              </Typography>
              <Typography variant="caption" gutterBottom>
                {prepay ? (
                  <FormattedMessage
                    id="incoterms.prepaying"
                    defaultMessage="You’re pre-paying import tax, duty, and associated fees. There will be no further charges due upon the shipment’s arrival in your country."
                  ></FormattedMessage>
                ) : (
                  <FormattedMessage
                    id="incoterms.estimated"
                    defaultMessage="An estimated {dduRange} of import tax, duty, and associated fees are due upon the shipment’s arrival in your country. Additional handling fees may apply."
                    values={{ dduRange }}
                  ></FormattedMessage>
                )}
              </Typography>
            </Fragment>
          ) : (
            <Grid container>
              <Grid item container direction="row" wrap="nowrap">
                <Grid item>
                  <Typography variant="subtitle1" className={classes.heading}>
                    <FormattedMessage
                      id="incoterms.heading"
                      defaultMessage="Import Tax, Duty, and associated fees"
                    ></FormattedMessage>
                    {prepay ? (
                      <Chip
                        label={intl.formatMessage({ id: 'incoterms.ddp', defaultMessage: 'Pre-pay' })}
                        color="primary"
                        className={`${classes.chip} ${classes.bold}`}
                      />
                    ) : (
                      <Chip
                        label={intl.formatMessage({ id: 'incoterms.ddu', defaultMessage: 'Pay on arrival' })}
                        className={`${classes.chip} ${classes.white}`}
                      />
                    )}
                  </Typography>
                </Grid>
                {prepay && (
                  <Grid item>
                    <Typography variant="h3" className={classes.bold}>
                      {ddpTotal}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Typography variant="caption" gutterBottom>
                  {prepay ? (
                    <FormattedMessage
                      id="incoterms.prepaying"
                      defaultMessage="You’re pre-paying import tax, duty, and associated fees. There will be no further charges due upon the shipment’s arrival in your country."
                    ></FormattedMessage>
                  ) : (
                    <FormattedMessage
                      id="incoterms.estimated"
                      defaultMessage="An estimated {dduRange} of import tax, duty, and associated fees are due upon the shipment’s arrival in your country. Additional handling fees may apply."
                      values={{ dduRange }}
                    ></FormattedMessage>
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        {!isNarrow && prepay && (
          <Grid item className={classes.price}>
            <Typography variant="h3">{ddpTotal}</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

import { makeStyles, Theme } from '@material-ui/core/styles';
import COLORS from '@/config/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  content: {
    overflow: 'hidden',
    maxWidth: 500,
    fontSize: 16,
  },
  fieldset: {
    margin: '5px 15px 20px',
    padding: 0,
    borderStyle: 'none',
    backgroundColor: '#F4F6F8',
    color: theme.palette.ink?.main,
    borderRadius: 4,
  },
  label: {
    display: 'flex',
    width: '100%',
    margin: '-6.5px 0',
    color: theme.palette.ink?.light,
    lineHeight: '32px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  field: {
    flexGrow: 1,
    padding: '0 12px',
  },
  input: {
    fontSize: 16,
    color: theme.palette.ink?.dark,
    '&::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder': {
      color: theme.palette.ink?.light,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  alert: {
    marginTop: 10,
    backgroundColor: theme.palette.red?.light,
    color: theme.palette.red?.dark,

    '& .MuiAlert-icon': { color: 'inherit' },
  },
  logo: {
    display: 'block',
    width: 100,
    margin: '15px auto',
    opacity: 0.5,
  },
}));

export const stripeStyle = {
  base: {
    iconColor: COLORS.inkFaded,
    color: COLORS.inkDark,
    fontWeight: '500',
    fontFamily: 'Karla, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',

    ':-webkit-autofill': {
      color: '#fce883',
      backgroundColor: 'transparent',
    },
    '::placeholder': {
      color: COLORS.inkLight,
    },
  },
  invalid: {
    iconColor: COLORS.redNormal,
    color: COLORS.redNormal,
  },
};

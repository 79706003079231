import { IncotermsValues } from './types';

export const emptyIncotermsValues: IncotermsValues = {
  ddpAmount: 0,
  dduRangeMin: 0,
  dduRangeMax: 0,
  maxAboveDdp: 0,
};

export function determineIncotermsValues(dduTax: number, ddpTax: number): IncotermsValues {
  return dduTax
    ? {
        ddpAmount: ddpTax,
        dduRangeMin: dduTax + 10,
        dduRangeMax: dduTax + 30,
        maxAboveDdp: (dduTax + 30 - ddpTax) / ddpTax,
      }
    : emptyIncotermsValues;
}

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '30px 0',
    '& > .MuiGrid-item': { width: '100%' },
  },
  paper: {
    width: 'calc(100% - 20px)',
    maxWidth: 600,
    margin: '10px auto',
    paddingBottom: 55,
    textAlign: 'center',
  },
  icon: {
    marginTop: 32,
    marginBottom: 12,
  },
  text: {
    maxWidth: 250,
    margin: 'auto',
  },
}));

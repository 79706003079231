import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '30px 0',
  },
  paper: {
    maxWidth: 'calc(100vw - 24px)',
    width: 600,
    margin: 10,
    textAlign: 'center',
  },
  description: {
    maxWidth: 240,
    margin: '40px auto 25px',
    lineHeight: 1.5,
  },
  row: {
    padding: '10px 24px',
    textAlign: 'left',
    borderBottom: '1px solid #F4F6F8',

    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px',
    },
  },
  disclaimer: {
    display: 'inline-block',
    padding: '10px 5px 20px',
  },
  total: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 24px',

    '& .MuiTypography-subtitle2': {
      fontWeight: 'bold',
    },

    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px',
    },
  },
  button: {
    margin: 20,
    padding: '8px 50px',
  },
  backButton: {
    margin: '20px 0',
    padding: 10,
    width: 80,
  },
  nextButton: {
    margin: '20px 0',
    padding: '8px 20px',

    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 20,
    },
  },
  reducePadding: {
    paddingRight: '3px !important',
  },
  topRow: {
    borderTop: '1px solid #F4F6F8',
  },
  buttonsContainer: {
    maxWidth: 600,
    height: 85,
    padding: '0 10px',
  },
  merchantFeeContainer: {
    width: '83%',
  },
  wordBreak: {
    wordBreak: 'break-word',
  },
}));

import { useState, FormEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CardElement, useElements } from '@stripe/react-stripe-js';
import { Dialog, DialogTitle, DialogContent, Grid, IconButton, TextField } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import StripeService from '@/services/stripe';
import { CourierService, Shipment } from '@/services/backer/types';
import Button from '@/components/Button';
import { formatFinancialValue } from '@/components/FinancialValue/FinancialValue';

import { stripeStyle, useStyles } from './styles';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

export interface PaymentDialogProps {
  shipment: Shipment;
  open: boolean;
  onClose?: () => void;
  onPayment?: () => Promise<void>;
}

export default function PaymentDialog({ open, shipment, onClose, onPayment }: PaymentDialogProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const elements = useElements();
  const { rates, courier_id, collect } = shipment;
  const [cardholder, setCardholder] = useState('');
  const [cardComplete, setCardComplete] = useState(false);
  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  function validateCard(event: StripeCardElementChangeEvent) {
    setCardComplete(event.complete);
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const cardElement = elements?.getElement(CardElement);

    if (!cardElement) {
      setErrorMessage('The credit card input could not be loaded');
      return;
    }

    setBusy(true);
    StripeService.confirmPayment(cardElement, cardholder)
      .then(onPayment)
      .catch(({ message }) => setErrorMessage(message))
      .finally(() => setBusy(false));
  }

  const preselectedService =
    rates?.find((courierService) => courierService.courier_id === courier_id) || ({} as CourierService);

  return (
    <Dialog
      fullScreen={isNarrow}
      open={open}
      onClose={onClose}
      disableScrollLock
      disableEnforceFocus
      aria-labelledby="payment"
    >
      <DialogTitle>
        <FormattedMessage id="payment.title" defaultMessage="Secure Payment"></FormattedMessage>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} component="fieldset" className={classes.fieldset}>
              <label className={classes.label}>
                <FormattedMessage id="payment.name" defaultMessage="Name on card" />
                <TextField
                  value={cardholder}
                  onChange={(event) => setCardholder(event.target.value)}
                  placeholder="Jane Doe"
                  required
                  InputProps={{ disableUnderline: true, className: classes.input }}
                  autoComplete="name"
                  className={classes.field}
                />
              </label>
            </Grid>
            <Grid item xs={12} component="fieldset" className={classes.fieldset}>
              <CardElement options={{ style: stripeStyle }} onChange={validateCard} />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" fullWidth disabled={cardholder?.length === 0 || !cardComplete} pending={busy}>
                <FormattedMessage
                  id="payment.submit"
                  defaultMessage="Pay {value}"
                  values={{
                    value: formatFinancialValue(
                      preselectedService.total_charge_after_prepayment,
                      collect.display_currency,
                    ),
                  }}
                />
              </Button>
              {errorMessage && (
                <Alert severity="error" className={classes.alert}>
                  {errorMessage}
                </Alert>
              )}

              <img src="/images/icon-stripe.svg" alt="powered by Stripe" className={classes.logo} />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

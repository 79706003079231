import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Paper, Typography } from '@material-ui/core';

import Title from '@/components/Title';

import { useStyles } from './styles';

export default function HomeScreen() {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid container direction="column" alignItems="center" spacing={2} className={classes.root}>
      <Grid item>
        <Title />
      </Grid>
      <Grid item>
        <Paper elevation={3} className={classes.paper}>
          <img
            src="/images/icon-lost.svg"
            alt={intl.formatMessage({ id: 'not-found.icon', defaultMessage: 'shipment not found icon' })}
            className={classes.icon}
          />
          <Typography variant="body1" className={classes.text}>
            <FormattedMessage
              id="home.text"
              defaultMessage="You need a different URL to access Easyship Collect. Please check your email for the right link."
            ></FormattedMessage>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

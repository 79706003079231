import { FormCountry } from '@/services/address/types';
import { useEffect, useState } from 'react';
import AddressResource from '@/services/address/resource';

export function useCountries() {
  const [countries, setCountries] = useState<FormCountry[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    AddressResource.getCountries()
      .then((res) => setCountries(res))
      .finally(() => setIsLoading(false));
  }, []);

  const validatePostalCode = (countryAlpha2: string, postalCode: string) => {
    const country = countries.find((country) => country.alpha2 === countryAlpha2);
    return new RegExp(country?.postalCodeRegex ?? '').test(postalCode);
  };

  return { countries, isLoading, validatePostalCode };
}

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '30px 0',
  },
  paper: {
    maxWidth: 600,
    margin: '0 10px',
    textAlign: 'center',
  },
  topThanks: {
    marginTop: 20,
    paddingTop: 30,
  },
  topRow: {
    borderTop: '1px solid #F4F6F8',
  },
  reducePadding: {
    paddingRight: '3px !important',
  },
  row: {
    padding: '10px 40px',
    textAlign: 'left',
    borderBottom: '1px solid #F4F6F8',

    [theme.breakpoints.down('xs')]: {
      padding: 15,
    },
  },
  total: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 40px',

    '& .MuiTypography-subtitle2': {
      fontWeight: 'bold',
    },

    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px',
    },
  },
  button: { padding: '8px 70px' },
  merchantFeeContainer: {
    width: '83%',
  },
  wordBreak: {
    wordBreak: 'break-word',
  }
}));

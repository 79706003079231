import mixpanel from 'mixpanel-browser';
import { createContext, useContext } from 'react';

export interface TrackingUserData {
  companyName: string;
  collectId: string;
}

class MixpanelTracking implements TrackingContextValue {
  constructor() {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN);
  }

  registerUser(user: TrackingUserData): void {
    mixpanel.register({ collect_id: user.collectId });
    mixpanel.set_group('company', user.companyName);
  }

  emitEvent(eventName: string, data?: Record<string, any> | undefined): void {
    mixpanel.track(eventName, data);
  }
}

class ConsoleTracking implements TrackingContextValue {
  registerUser(user: TrackingUserData): void {
    console.debug('[Tracking - register user]', user);
  }

  emitEvent(eventName: string, data?: Record<string, any> | undefined): void {
    console.debug('[Tracking - emit]', eventName, data);
  }
}

interface TrackingContextValue {
  registerUser(user: TrackingUserData): void;
  emitEvent(eventName: string, data?: Record<string, any>): void;
}

const TrackingContext = createContext<TrackingContextValue | undefined>(undefined);

interface TrackingProviderProps {
  children?: React.ReactNode;
}

function TrackingProvider({ children }: TrackingProviderProps) {
  const hasMixpanelToken = !!import.meta.env.VITE_MIXPANEL_TOKEN;
  const value = hasMixpanelToken ? new MixpanelTracking() : new ConsoleTracking();

  return <TrackingContext.Provider value={value}>{children}</TrackingContext.Provider>;
}

function useTracking() {
  const trackingContext = useContext(TrackingContext);

  if (trackingContext === undefined) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }

  return trackingContext;
}

export { useTracking, TrackingProvider, MixpanelTracking, ConsoleTracking };
